import { useEffect, useState, useRef } from "react";
import { fetchData, deleteData, putData, postData } from "../../endpoints";
import { useAssets, useMobile } from "../../hooks/index";
import { Spin } from "antd";
import AddPatient from "../AddPatient";
import { Modal, Table, Button, Input, Space } from "antd";
import { SearchOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import access from "../../assets/access";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
import Highlighter from "react-highlight-words";
const privilage = Cookies.get("privilege") ?? full;
const Detile = ({
  patient,
  setPatient,
  getPatient,
  loadingPage,
  serviceTypes,
  domain,
  isEdit,
}) => {
  const { getFile } = useAssets();
  const isMobile = useMobile();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const UserId = localStorage.getItem("id");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editStep, seteditstep] = useState(2);
  const [isOpenAssign, setisOpenAssign] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [selectedRowKeysadmin, setSelectedRowKeysadmin] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [adminsOnPatient, setAdminsOnPatient] = useState([]);
  const [internalLoadnig, setInternalLoading] = useState(false);
  const searchInput = useRef(null);

  const getAdmins = async (page = 1, reload = false) => {
    var temp = admins;
    if (reload) {
      temp = [];
    }
    setLoading(true);
    if (admins.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        "admin/user/?disabledAt=false&deletedAt=false&perPage=15&type[]=admin&type[]=super_user&page=" +
          page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setAdmins(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res.data.data)
          );
          console.log(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res.data.data)
          );
        } else {
          setAdmins(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([...res.data.data, {}])
          );
          console.log(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setLoading(false);
  };
  const getAdminsOnPatient = async (patientId) => {
    setInternalLoading(true);
    var res = await fetchData(
      `admin/user-assignment?itemType=patient&includes[]=user&deletedAt=false&itemId=` +
        patientId
    );
    if (res.status == 200) {
      setAdminsOnPatient(
        res.data.data.map((a) => {
          if (a.user)
            return {
              ...a,
              name: a.user.firstName + " " + a.user.lastName,
              email: a.user.email,
            };
          else {
            return { ...a };
          }
        })
      );
    }
    setInternalLoading(false);
  };
  const getColumnSearchPropsadmin = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchadmin(selectedKeys, confirm, dataIndex)
          }
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchadmin(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetadmin(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleSearchadmin = async (selectedKeys, confirm, dataIndex) => {
    setLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setAdmins([]);
      await getAdmins();
    } else {
      let search = "";
      if (dataIndex == "type") search = "type";
      if (dataIndex == "email") search = "email";
      if (dataIndex == "name") search = "firstName";

      var res = await fetchData(
        `admin/user/?disabledAt=false&deletedAt=false&perPage=4000&type[]=admin&${search}=` +
          selectedKeys[0]
      );

      if (res.status == 200) {
        setAdmins(res.data.data);
      }
    }
    setLoading(false);
  };
  const handleResetadmin = (clearFilters) => {
    clearFilters();
    setSearchText("");
    getAdmins(1, true);
  };
  const data_admin = admins.map((a, i) => {
    return { ...a, key: a.id, name: a.firstName + " " + a.lastName };
  });
  const columns_admin = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // width: "20%",
      ...getColumnSearchPropsadmin("name"),
      render: (u) => <div>{u ? u : "name not provide"}</div>,
    },
    {
      title: "Email/Phone Number",
      dataIndex: "email",
      key: "email",
      // width: "30%",
      ...getColumnSearchPropsadmin("email"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      // width: "20%",
      ...getColumnSearchPropsadmin("type"),
    },
  ];
  const rowSelectionadmin = {
    selectedRowKeysadmin,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeysadmin(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const getUser = async () => {
    setLoading(true);
    var res = await fetchData(`admin/user/${patient.userId}`);
    if (res.status == 200) {
      setUser(res.data.data);
    }
    setLoading(false);
  };
  const { confirm } = Modal;
  const showRemoveConfirm = async (a) => {
    confirm({
      title: "Are you sure you want to unassign this assignment?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeAssign(a);
      },
      onCancel() {},
    });
  };
  const getAssinees = async (userId) => {
    var res = await fetchData(
      `admin/user-assignment?deletedAt=false&userId=` + userId
    );
    if (res.status == 200) {
      Cookies.set(
        "assinees_patient",
        res.data.data
          .filter((a) => {
            return a.itemType == "patient";
          })
          .map((p) => {
            return p.itemId;
          })
      );

      Cookies.set(
        "assinees_purchase",
        res.data.data
          .filter((a) => {
            return a.itemType == "patient_purchase";
          })
          .map((p) => {
            return p.itemId;
          })
      );
    } else {
      Modal.error({ title: res });
    }
  };
  const removeAssign = async (a) => {
    setInternalLoading(true);
    var res = await deleteData("admin/user-assignment/" + a.id);
    if (res.status == 200) {
      getAssinees(UserId);
      getAdminsOnPatient(patient.id);
      Modal.success({ title: res.data.message });
    } else {
      Modal.error({ title: res });
    }
    setInternalLoading(false);
  };
  const showDeleteConfirm = async () => {
    confirm({
      title: "Are you sure you want to disable this patient?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removePatient();
      },
      onCancel() {},
    });
  };
  const removePatient = async () => {
    setLoading(true);
    var res = await deleteData(`admin/patient/${patient.id}`);
    if (res.status === 200) {
      setLoading(false);
      Modal.success({ title: "Patient has been successfully disabled." });
      await getPatient();
    } else {
      setLoading(false);
      Modal.error({ title: "There was a problem, please try again." });
    }
  };

  useEffect(() => {
    getUser();
    getAdmins();
    getAdminsOnPatient(patient.id);
  }, []);
  function insertBlankAfterEveryThreeCharacters(str) {
    var str = str.split(" ").join("").split("");
    var formatted = [];

    for (var i = 0; i < 4 && str.length; i++) {
      formatted.push(str.shift());
    }
    if (str.length) formatted.push(" ");
    while (str.length) {
      for (var i = 0; i < 3 && str.length; i++) {
        formatted.push(str.shift());
      }
      if (str.length) formatted.push(" ");
    }
    return formatted.join("");
  }
  const verifyPatient = async (id) => {
    setLoading(true);
    var res = await putData(`admin/patient/${id}/verify`);
    if (res.status === 200) {
      Modal.success({ title: res.data.message });
      getPatient(1);
    } else {
      Modal.error({ title: res });
    }
    setLoading(false);
  };

  return (
    <div
      className="d-flex gap-3 w-100"
      style={isMobile ? { flexDirection: "column" } : { flexDirection: "row" }}
      dir="ltr"
    >
      <div className="detile col-12 col-lg-8 d-flex flex-column py-3 gap-3 pb-5 mb-3">
        {loadingPage && (
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
        )}
        {!loadingPage && (
          <>
            <div>
              <table>
                <thead>
                  <th
                    colSpan={2}
                    style={
                      isMobile ? { fontSize: "13px" } : { fontSize: "18px" }
                    }
                  >
                    Personal Information
                    {privilage.indexOf(
                      access.patientManegment.editPatient.personal
                    ) > -1 && (
                      <svg
                        onClick={() => {
                          seteditstep(2);
                          setIsModalOpen(true);
                          //   setIsOpen(false);
                        }}
                        className={isMobile ? "edit-icon-mobile" : "edit-icon"}
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                            fill="#18324E"
                          />
                          <path
                            d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                            fill="#18324E"
                          />
                        </g>
                      </svg>
                    )}
                  </th>
                </thead>

                <tbody className="personalTable">
                  <tr>
                    <td
                      style={{
                        borderRight: "1px solid rgba(242, 242, 242, 1)",
                      }}
                    >
                      First Name: {" " + patient.firstName}
                    </td>
                    <td>Last Name: {" " + patient.lastName}</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderRight: "1px solid rgba(242, 242, 242, 1)",
                      }}
                    >
                      Date of Birth:
                      {patient.birthday
                        ? " " +
                          patient.birthday.getFullYear() +
                          "/" +
                          patient.birthday.getMonth() +
                          "/" +
                          patient.birthday.getDate()
                        : " "}
                    </td>
                    <td>
                      Sex:{" " + patient.sex}
                      {patient.sex == "Other"
                        ? "/ " + patient.sexExtraInfo
                        : ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderRight: "1px solid rgba(242, 242, 242, 1)",
                      }}
                    >
                      Gender: {" " + patient.gender}
                      {patient.gender == "Other"
                        ? "/ " + patient.genderExtraInfo
                        : ""}
                    </td>
                    <td>Relationship: {" " + patient.relationship} </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderRight: "1px solid rgba(242, 242, 242, 1)",
                      }}
                    >
                      Care Card:{" "}
                      {" " +
                        insertBlankAfterEveryThreeCharacters(patient.careCard)}
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table>
                <thead>
                  <th
                    colSpan={3}
                    style={
                      isMobile ? { fontSize: "13px" } : { fontSize: "18px" }
                    }
                  >
                    Emergency Contact{" "}
                    {privilage.indexOf(
                      access.patientManegment.editPatient.emerjency
                    ) > -1 && (
                      <svg
                        onClick={() => {
                          seteditstep(4);
                          setIsModalOpen(true);
                          //   setIsOpen(false);
                        }}
                        className={isMobile ? "edit-icon-mobile" : "edit-icon"}
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                            fill="#18324E"
                          />
                          <path
                            d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                            fill="#18324E"
                          />
                        </g>
                      </svg>
                    )}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}> Name</td>
                    <td style={{ fontWeight: "bold" }}>phone Number</td>
                    <td style={{ fontWeight: "bold" }}> Relationship</td>
                  </tr>
                  {patient.emergencyContacts.map((e) => {
                    return (
                      <tr>
                        <td>{e.fullName ? e.fullName : "No Name Set"}</td>
                        <td>{e.phoneNumber}</td>
                        <td>{e.relationship}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div>
              <table>
                <thead>
                  <th
                    colSpan={3}
                    style={
                      isMobile ? { fontSize: "13px" } : { fontSize: "18px" }
                    }
                  >
                    Teacher/Care Provider Email Address{" "}
                    {privilage.indexOf(
                      access.patientManegment.editPatient.teacher
                    ) > -1 && (
                      <svg
                        onClick={() => {
                          seteditstep(5);
                          setIsModalOpen(true);
                          //   setIsOpen(false);
                        }}
                        className={isMobile ? "edit-icon-mobile" : "edit-icon"}
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                            fill="#18324E"
                          />
                          <path
                            d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                            fill="#18324E"
                          />
                        </g>
                      </svg>
                    )}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>First Name</td>
                    <td style={{ fontWeight: "bold" }}>Last Name</td>
                    <td style={{ fontWeight: "bold" }}>Email</td>
                  </tr>
                  {patient.teachers.map((t) => {
                    return (
                      <tr>
                        <td>{t.firstName}</td>
                        <td>{t.lastName}</td>
                        <td>{t.email}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div>
              <table>
                <thead>
                  <th
                    colSpan={4}
                    style={
                      isMobile ? { fontSize: "13px" } : { fontSize: "18px" }
                    }
                  >
                    Referral Information
                    {privilage.indexOf(
                      access.patientManegment.editPatient.refferral
                    ) > -1 && (
                      <svg
                        onClick={() => {
                          seteditstep(6);
                          setIsModalOpen(true);
                          //   setIsOpen(false);
                        }}
                        className={isMobile ? "edit-icon-mobile" : "edit-icon"}
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                            fill="#18324E"
                          />
                          <path
                            d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                            fill="#18324E"
                          />
                        </g>
                      </svg>
                    )}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>First Name</td>
                    <td style={{ fontWeight: "bold" }}>Last Name</td>
                    <td style={{ fontWeight: "bold" }}>Email</td>
                    <td style={{ fontWeight: "bold" }}>Fax</td>
                  </tr>

                  {patient.referralBy && (
                    <tr>
                      <td>{patient.referralBy.firstName}</td>
                      <td>{patient.referralBy.lastName}</td>
                      <td>{patient.referralBy.email}</td>
                      <td>{patient.referralBy.fax}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div>
              <table>
                <thead>
                  <th
                    colSpan={1}
                    style={
                      isMobile ? { fontSize: "13px" } : { fontSize: "18px" }
                    }
                  >
                    Services
                    {privilage.indexOf(
                      access.patientManegment.editPatient.serviceType
                    ) > -1 && (
                      <svg
                        onClick={() => {
                          seteditstep(7);
                          setIsModalOpen(true);
                          //   setIsOpen(false);
                        }}
                        className={isMobile ? "edit-icon-mobile" : "edit-icon"}
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M9.46769 5.06373L9.65168 5.24759L2.89883 12.0004H0V9.10174L6.75285 2.34857L9.28394 4.87966L9.46769 5.06373Z"
                            fill="#18324E"
                          />
                          <path
                            d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                            fill="#18324E"
                          />
                        </g>
                      </svg>
                    )}
                  </th>
                </thead>
                <tbody>
                  {patient.serviceTypes.map((s) => {
                    return (
                      <tr>
                        <td>{s.serviceType.title}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      <div
        className={
          isMobile
            ? "col-lg-4 col-12 text-align-left my-4 d-flex justify-content-center"
            : "col-lg-4 col-12 text-align-left my-4"
        }
      >
        <div className="user-box px-3 py-3">
          <span style={{ fontWeight: "700", fontSize: "20px" }}>
            Main User:
          </span>
          <div className="d-flex flex-row gap-2 w-100 my-4" dir="ltr">
            {/* <img
              src={patient.photo ? domain + patient.photo : getFile("Profile")}
              width={isMobile ? "40px" : "70px"}
              height={isMobile ? "40px" : "70px"}
              style={{ borderRadius: "30px" }}
            /> */}
            <LazyLoadImage
              effect="blur"
              width={isMobile ? "40px" : "70px"}
              height={isMobile ? "40px" : "70px"}
              style={{ borderRadius: "30px" }}
              placeholderSrc={getFile("Profile")}
              src={patient.photo ? domain + patient.photo : getFile("Profile")}
            />
            <div
              className="d-flex flex-column"
              style={{
                justifyContent: "center",
                width: "70%",
                textAlign: "center",
              }}
            >
              {loading ? (
                <Spin tip="Loading" size="large">
                  <div className="content" />
                </Spin>
              ) : (
                <>
                  <span style={{ fontWeight: "600", fontSize: "16px" }}>
                    {user.firstName + " " + user.lastName}
                  </span>
                </>
              )}
            </div>
          </div>
          <div
            style={{
              marginTop: "-10px",
              marginBottom: "20px",
            }}
            className="d-flex flex-column"
          >
            <span
              style={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              Email: {user.email}
            </span>
            <span
              style={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              Phone Number: {user.phoneNumber}
            </span>
          </div>
          <div className="line" />
          <div className="d-flex flex-column gap-1">
            <h3>Date Added:</h3>
            <h4>{new Date(patient.createdAt).toDateString()}</h4>
            <br />
            <h3>MRN:</h3>
            <h4>
              {patient.verifiedAt
                ? patient.id
                : privilage.indexOf(access.patientManegment.verifyPatient) >
                    -1 && (
                    <strong
                      onClick={() => {
                        verifyPatient(patient.id);
                      }}
                      style={{
                        color: "rgba(46, 108, 223, 1)",
                        cursor: "pointer",
                      }}
                    >
                      Verify
                    </strong>
                  )}
            </h4>
          </div>
          <div className="line" />

          {adminsOnPatient.length > 0 && !internalLoadnig ? (
            <>
              <div>
                <h3> Assignees: </h3>
                {adminsOnPatient.map((a) => {
                  return (
                    <div className="d-flex flex-row gap-3 my-2">
                      <h3 className="col-7">{a.name}</h3>
                      <Button
                        type="default"
                        danger
                        onClick={() => {
                          showRemoveConfirm(a);
                        }}
                      >
                        Unassign
                      </Button>
                    </div>
                  );
                })}
              </div>
              <div className="line" />
            </>
          ) : (
            internalLoadnig && (
              <div className="align-items-center">
                <Spin />
              </div>
            )
          )}

          <div className="d-flex flex-row gap-2">
            <h2
              style={{ cursor: "pointer", color: "#2E6CDF" }}
              onClick={async () => {
                setisOpenAssign(true);
                if (admins.length == 0) {
                  await getAdmins();
                }
              }}
            >
              Add Assignees
            </h2>
            {privilage.indexOf(access.patientManegment.disablePatient) > -1 && (
              <h2 style={{ cursor: "pointer" }} onClick={showDeleteConfirm}>
                Disable
              </h2>
            )}
          </div>
        </div>
      </div>
      <Modal
        title=""
        open={isOpenAssign}
        style={{ top: "5vh" }}
        width={isMobile ? "90vw" : "65vw"}
        footer={
          <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              size="large"
              onClick={async () => {
                setLoading(true);
                var success = true;
                for (var row in selectedRowKeysadmin) {
                  var res = await postData("admin/user-assignment", {
                    userId: selectedRowKeysadmin[row],
                    itemType: "patient",
                    itemId: patient.id,
                  });
                  if (res.status != 201) {
                    success = false;
                    Modal.error({ title: res });
                  }
                }
                if (success) {
                  Modal.success({ title: res.data.message });
                }
                setLoading(false);
                getAdminsOnPatient(patient.id);
                getAssinees(UserId);
                setisOpenAssign(false);
              }}
            >
              Assign
            </Button>
          </div>
        }
        onCancel={() => setisOpenAssign(false)}
        closeIcon={null}
        destroyOnClose
      >
        <div>
          <div style={{ textAlign: "end" }}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setisOpenAssign(false)}
              style={{ cursor: "pointer" }}
            >
              <path
                d="M9.71012 8.00014L15.6459 2.06441C16.118 1.59228 16.118 0.826413 15.6459 0.354269C15.1738 -0.11809 14.4079 -0.11809 13.9358 0.354269L7.99997 6.29025L2.06442 0.354507C1.59229 -0.117852 0.826181 -0.117852 0.354272 0.354507C-0.118091 0.826633 -0.118091 1.5925 0.354272 2.06465L6.28982 8.00014L0.354272 13.9359C-0.118091 14.408 -0.118091 15.1739 0.354272 15.646C0.590333 15.8821 0.899664 16 1.2092 16C1.51852 16 1.82811 15.8821 2.06413 15.646L7.99991 9.71052L13.9357 15.646C14.1718 15.8821 14.4815 16 14.7906 16C15.0997 16 15.4095 15.8821 15.6455 15.646C16.1177 15.1739 16.1177 14.408 15.6455 13.9359L9.71012 8.00014Z"
                fill="#4C4C4C"
              />
            </svg>
          </div>

          <div
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: "-30px",
            }}
          >
            <div style={{ fontSize: "24px", fontWeight: "700" }}>
              Add Assignees
            </div>
            <hr />
          </div>
          <Table
            scroll={{ x: "auto" }}
            loading={loading}
            rowSelection={{
              type: "checkbox",
              ...rowSelectionadmin,
            }}
            columns={columns_admin}
            size="small"
            style={{ width: "100%" }}
            dataSource={data_admin}
            pagination={{
              onChange: async (page) => {
                await getAdmins(page);
              },
              pageSize: 15,
            }}
          />
        </div>
      </Modal>
      <Modal
        open={isModalOpen}
        footer={(null, null)}
        width={isMobile ? "90vw" : "55vw"}
        style={isMobile ? {} : { top: "5vh" }}
        destroyOnClose={true}
        onCancel={() => {
          // Modal.destroyAll()
          // seteditstep(2);
          // getPatient();
          setIsModalOpen(false);
        }}
      >
        <AddPatient
          isOpen={isModalOpen}
          setIsModalClose={setIsModalOpen}
          p={patient}
          userId={user.id}
          getPatients={getPatient}
          isEdit={true}
          serviceTypes={serviceTypes}
          editstep={editStep}
        />
      </Modal>
    </div>
  );
};
export default Detile;
