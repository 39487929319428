import React, { useState, useRef, useEffect } from "react";
import { useMobile } from "../../../hooks";

import { Select, Modal,  Checkbox, Spin, Button } from "antd";

import { fetchData, fetchDataWithParams, putData } from "../../../endpoints";
import { useConfig } from "../../../hooks/useConfig";

function NotificationSetting(user = null) {
  const [openCustom, setopenCustom] = useState(false);
  const [customType, setCustomType] = useState("");
  const [settings, setSettings] = useState([
    { channel: "app", status: "default" },
    { channel: "email", status: "default" },
    { channel: "sms", status: "default" },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [triggers, setTriggers] = useState([]);
  const [triggersDesc,setTriggersDescs]=useState([])
  const { getUserConfig } = useConfig()
  const getNotificationSetting = async () => {
    setIsLoading(true);
    var res;
    if (user.id)
      res = await fetchData(`admin/notification/user/${user.id}/channel`);
    else {
      res = await fetchData(`notification/channel`);
    }
    if (res.status == 200) {
      setSettings(res.data.data);
    } else {
      Modal.error({ title: res });
    }
    getUserConfig();
    var config = JSON.parse(localStorage.getItem("config"));
    setTriggersDescs(config.data.data.notification.trigger)
    setIsLoading(false);
  };
  const changeNotificationSetting = async (channel, data) => {
    setIsLoading(true);
    var res;
    if (user.id)
      res = await putData(`admin/notification/user/${user.id}/channel`, {
        channel: channel,
        status: data,
      });
    else {
      res = await putData(`notification/channel`, {
        channel: channel,
        status: data,
      });
    }
    if (res.status == 200) {
      var temp = settings;
      temp = temp.filter((d) => {
        return d.channel != channel;
      });
      temp = [...temp, { channel: channel, status: data }];
      setSettings(temp);
    } else {
      Modal.error({ title: res });
    }

    setIsLoading(false);
  };

  const changeCustomSetting = async (channel, id, active) => {
    setIsLoading(true);
    var res;
    if(user.id){
       res = await putData(`admin/notification/user/${user.id}/trigger/${id}`, {
        channel: channel,
        active: active,
      });
    }else{
       res = await putData(`notification/trigger/${id}`, {
        channel: channel,
        active: active,
      });
    }
    
   await getTriggers(channel)
    setIsLoading(false);
  };

  async function getTriggers(type) {
    setIsLoading(true);
    var res
    if(user.id){
      res= await fetchData(`admin/notification/user/${user.id}/trigger?channel=${type}`)
    }else
    { res = await fetchData(`notification/trigger?channel=${type}`);}
    if (res.status == 200) {
      setTriggers(res.data.data);
      const activeKeys = res.data.data
        .filter((item) => item.active)
        .map((item) => item.id);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    getNotificationSetting();
  }, []);
 
  const isMobile = useMobile();


  return (
    <>
      <div
        style={
          isMobile
            ? { padding: "10px", direction: "ltr" }
            : { padding: "40px 40px", direction: "ltr" }
        }
      >
        <div style={{ fontSize: "20px", fontWeight: "700" }}>
          Notifications Settings
        </div>
        <div
          style={
            isMobile
              ? { marginTop: "16px", width: "100%" }
              : { marginTop: "32px", width: "85%" }
          }
        >
          <div
            style={
              isMobile
                ? {
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px 14px",
                    boxShadow: "0 3px 3px rgb(0 0 0 / 0.07)",
                    minHeight: "80px",
                    alignItems: "center",
                  }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "24px 28px",
                    boxShadow: "0 3px 3px rgb(0 0 0 / 0.07)",
                    minHeight: "80px",
                    alignItems: "center",
                  }
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <svg
                width={isMobile ? "12" : "24"}
                height={isMobile ? "12" : "24"}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 10H7C9 10 10 9 10 7V5C10 3 9 2 7 2H5C3 2 2 3 2 5V7C2 9 3 10 5 10Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 22H19C21 22 22 21 22 19V17C22 15 21 14 19 14H17C15 14 14 15 14 17V19C14 21 15 22 17 22Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                style={
                  isMobile
                    ? {
                        fontSize: "12px",
                        fontWeight: "500",
                        marginLeft: "8px",
                        whiteSpace: "nowrap",
                      }
                    : {
                        fontSize: "18px",
                        fontWeight: "500",
                        marginLeft: "16px",
                      }
                }
              >
                In-app
              </div>
              <div
                style={
                  isMobile
                    ? { display: "none" }
                    : {
                        marginLeft: "100px",
                        paddingLeft: "16px",
                        borderLeft: "1px solid #E1E1E1",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "50px",
                      }
                }
              >
                Recommended settings
              </div>
            </div>

            <div>
              {" "}
              <Select
                style={isMobile ? {} : { width: "170px" }}
                defaultValue={
                  settings.filter((d) => {
                    return d.channel == "app";
                  })[0].status
                }
                onSelect={async (data) => {
                  if (data == "custom") {
                    setCustomType("In-app");
                    setopenCustom(true);
                    await getTriggers("app");
                  }
                  await changeNotificationSetting("app", data);
                }}
              >
                <Select.Option value="default">Default</Select.Option>
                <Select.Option value="disabled">Disabled</Select.Option>
                <Select.Option value="custom">Custom</Select.Option>
              </Select>
            </div>
          </div>
          <div
            style={
              isMobile
                ? {
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px 14px",
                    boxShadow: "0 3px 3px rgb(0 0 0 / 0.07)",
                    minHeight: "80px",
                    alignItems: "center",
                  }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "24px 28px",
                    boxShadow: "0 3px 3px rgb(0 0 0 / 0.07)",
                    minHeight: "80px",
                    alignItems: "center",
                  }
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <svg
                width={isMobile ? "12" : "24"}
                height={isMobile ? "12" : "24"}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.5882 19.1899H3.57173C2.68281 19.189 1.96179 18.4689 1.96094 17.5791V6.4108C1.96179 5.52103 2.68281 4.80085 3.57173 4.8H20.5882C21.4771 4.80086 22.1981 5.52103 22.199 6.4108V17.5791C22.1981 18.4689 21.4771 19.189 20.5882 19.1899ZM4.23391 17.8262H19.926L15.3066 12.982C14.5183 13.8087 13.8484 14.5101 13.4299 14.9481V14.949C12.6782 15.6828 11.4782 15.682 10.7282 14.9464C10.3055 14.5033 9.63727 13.8035 8.85317 12.9819L4.23391 17.8262ZM16.2492 11.9949L20.8354 16.8034V7.18639C19.6183 8.46225 17.8192 10.3492 16.2492 11.9949ZM3.32453 7.18639V16.8034L7.91137 11.9949C6.34233 10.35 4.54744 8.46821 3.32453 7.18639ZM4.23306 6.16366C6.35693 8.39064 10.3363 12.5634 11.7152 14.0062H11.716C11.9163 14.2065 12.241 14.2073 12.4422 14.0079C13.8024 12.5846 17.809 8.38471 19.926 6.16362L4.23306 6.16366Z"
                  fill="black"
                />
              </svg>
              <div
                style={
                  isMobile
                    ? {
                        fontSize: "12px",
                        fontWeight: "500",
                        marginLeft: "8px",
                      }
                    : {
                        fontSize: "18px",
                        fontWeight: "500",
                        marginLeft: "16px",
                      }
                }
              >
                Email
              </div>
              <div
                style={
                  isMobile
                    ? { display: "none" }
                    : {
                        marginLeft: "100px",
                        paddingLeft: "16px",
                        borderLeft: "1px solid #E1E1E1",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "50px",
                      }
                }
              >
                Set custom settings
              </div>
            </div>
            <div>
              {" "}
              <Select
                style={isMobile ? {} : { width: "170px" }}
                value={
                  settings.filter((d) => {
                    return d.channel == "email";
                  })[0].status
                }
                onSelect={async (data) => {
                  if (data == "custom") {
                    setCustomType("Email");
                    setopenCustom(true);

                    await getTriggers("email");
                  }
                  await changeNotificationSetting("email", data);
                }}
              >
                <Select.Option value="default">Default</Select.Option>
                <Select.Option value="disabled">Disabled</Select.Option>
                <Select.Option value="custom">Custom</Select.Option>
              </Select>
            </div>
          </div>
          <div
            style={
              isMobile
                ? {
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px 14px",
                    boxShadow: "0 3px 3px rgb(0 0 0 / 0.07)",
                    minHeight: "80px",
                    alignItems: "center",
                  }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "24px 28px",
                    boxShadow: "0 3px 3px rgb(0 0 0 / 0.07)",
                    minHeight: "80px",
                    alignItems: "center",
                  }
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <svg
                width={isMobile ? "12" : "24"}
                height={isMobile ? "12" : "24"}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27 22.5458V8.84395C27 7.95708 26.6423 7.10653 26.0056 6.47942C25.3689 5.85231 24.5054 5.5 23.6049 5.5H15.1173C14.4334 5.50199 13.766 5.70737 13.2026 6.08924C12.6392 6.47112 12.2061 7.01168 11.9599 7.64013H6.69753C6.24732 7.64013 5.81554 7.81628 5.4972 8.12984C5.17885 8.44339 5 8.86867 5 9.3121V14.2444C5 14.6879 5.17885 15.1131 5.4972 15.4267C5.81554 15.7402 6.24732 15.9164 6.69753 15.9164H11.7222V23.156C11.7222 24.0429 12.0799 24.8935 12.7166 25.5206C13.3533 26.1477 14.2169 26.5 15.1173 26.5H23.6049C24.5054 26.5 25.3689 26.1477 26.0056 25.5206C26.6423 24.8935 27 24.0429 27 23.156V22.5458ZM15.1173 7.17197H23.6049C24.0552 7.17197 24.4869 7.34813 24.8053 7.66168C25.1236 7.97524 25.3025 8.40051 25.3025 8.84395V21.7098H13.4198V16.9948L14.4043 18.1986C14.4895 18.3025 14.5986 18.3848 14.7226 18.4386C14.8467 18.4924 14.982 18.5162 15.1173 18.508C15.1878 18.5161 15.259 18.5161 15.3295 18.508C15.4882 18.4663 15.6312 18.3801 15.7412 18.2599C15.8512 18.1397 15.9234 17.9906 15.9491 17.8308L16.2631 15.9415H17.8248C18.2751 15.9415 18.7068 15.7653 19.0252 15.4518C19.3435 15.1382 19.5224 14.7129 19.5224 14.2695V9.3121C19.5224 8.86867 19.3435 8.44339 19.0252 8.12984C18.7068 7.81628 18.2751 7.64013 17.8248 7.64013H13.9205C14.2415 7.33532 14.6714 7.16716 15.1173 7.17197ZM6.69753 9.3121H17.7739V14.2444H15.4992C15.2958 14.2418 15.0982 14.3113 14.9424 14.4402C14.7866 14.5691 14.683 14.7488 14.6505 14.9467L14.5401 15.6322L13.6489 14.5537C13.6097 14.5084 13.5639 14.469 13.5131 14.4367C13.4707 14.4367 13.4367 14.3782 13.3858 14.3531L13.216 14.328C13.1597 14.3201 13.1026 14.3201 13.0463 14.328H6.69753V9.3121ZM23.6049 24.828H15.1173C14.7041 24.8317 14.3037 24.6869 13.9912 24.4207C13.6787 24.1544 13.4755 23.785 13.4198 23.3818H25.3025C25.2467 23.785 25.0435 24.1544 24.731 24.4207C24.4185 24.6869 24.0181 24.8317 23.6049 24.828Z"
                  fill="black"
                />
                <path
                  d="M12.2706 12.6561C12.7394 12.6561 13.1194 12.2818 13.1194 11.8201C13.1194 11.3584 12.7394 10.9841 12.2706 10.9841C11.8019 10.9841 11.4219 11.3584 11.4219 11.8201C11.4219 12.2818 11.8019 12.6561 12.2706 12.6561Z"
                  fill="black"
                />
                <path
                  d="M14.8175 12.6561C15.2863 12.6561 15.6663 12.2818 15.6663 11.8201C15.6663 11.3584 15.2863 10.9841 14.8175 10.9841C14.3488 10.9841 13.9688 11.3584 13.9688 11.8201C13.9688 12.2818 14.3488 12.6561 14.8175 12.6561Z"
                  fill="black"
                />
                <path
                  d="M9.71595 12.6561C10.1847 12.6561 10.5647 12.2818 10.5647 11.8201C10.5647 11.3584 10.1847 10.9841 9.71595 10.9841C9.24719 10.9841 8.86719 11.3584 8.86719 11.8201C8.86719 12.2818 9.24719 12.6561 9.71595 12.6561Z"
                  fill="black"
                />
              </svg>
              <div
                style={
                  isMobile
                    ? {
                        fontSize: "12px",
                        fontWeight: "500",
                        marginLeft: "8px",
                      }
                    : {
                        fontSize: "18px",
                        fontWeight: "500",
                        marginLeft: "16px",
                      }
                }
              >
                SMS
              </div>
              <div
                style={
                  isMobile
                    ? { display: "none" }
                    : {
                        marginLeft: "100px",
                        paddingLeft: "16px",
                        borderLeft: "1px solid #E1E1E1",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "50px",
                      }
                }
              >
                No more notifications
              </div>
            </div>
            <div>
              <Select
                style={isMobile ? {} : { width: "170px" }}
                defaultValue={
                  settings.filter((d) => {
                    return d.channel == "sms";
                  })[0].status
                }
                onSelect={async (data) => {
                  if (data == "custom") {
                    setCustomType("SMS");
                    setopenCustom(true);
                    await getTriggers("sms");
                  }
                  await changeNotificationSetting("sms", data);
                }}
              >
                <Select.Option value="default">Default</Select.Option>
                <Select.Option value="disabled">Disabled</Select.Option>
                <Select.Option value="custom">Custom</Select.Option>
              </Select>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title=""
        width={isMobile ? "90vw" : "65vw"}
        open={openCustom}
        okText="Next"
        onCancel={() => setopenCustom(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <div
          style={{
            fontSize: "30px",
            fontWeight: "700",
            textAlign: "center",
            marginBottom: "24px",
          }}
        >
          {customType} Notifications
        </div>
        <hr style={{ width: "80%", margin: "auto", marginBottom: "40px" }} />
     {isLoading? <Spin >
      <div style={{width: "80%", margin: "auto" , overflowY:'scroll'}} className="d-flex flex-column gap-3 align-items-center justify-contents-center">
       {triggers.map((trigger)=>{
          return <div className="d-flex flex-row w-100 gap-3 align-items-center">
             <Checkbox
             checked={trigger.active}
             onChange={(e)=>{
              
              if (customType == "In-app") {
                changeCustomSetting("app", trigger.id, e.target.checked);
              } else if (customType == "Email") {
                changeCustomSetting("email", trigger.id, e.target.checked);
              } else {
                changeCustomSetting("sms", trigger.id, e.target.checked);
              }
             }}
             />
             
             <div
             style={{padding:'15px 15px',
              border: '1px solid #D1D1D1',
              color:'#4C4C4C',
              fontWeight:'600',
              fontSize:'16px',
              width:'90%'

            }}
             >
              {triggersDesc.title[trigger.key] }
             <p style={{              fontWeight:'500',
              fontSize:'12px',}}> {triggersDesc.description[trigger.key] }</p>
             </div>
          </div>
        })}
       </div>
      </Spin>:  <div style={{width: "80%", margin: "auto" , overflowY:'scroll'}} className="d-flex flex-column gap-3 align-items-center justify-contents-center">
       {triggers.map((trigger)=>{
          return <div className="d-flex flex-row w-100 gap-3 align-items-center">
             <Checkbox
             checked={trigger.active}
             onChange={(e)=>{
              
              if (customType == "In-app") {
                changeCustomSetting("app", trigger.id, e.target.checked);
              } else if (customType == "Email") {
                changeCustomSetting("email", trigger.id, e.target.checked);
              } else {
                changeCustomSetting("sms", trigger.id, e.target.checked);
              }
             }}
             />
             
             <div
             style={{padding:'15px 15px',
              border: '1px solid #D1D1D1',
              color:'#4C4C4C',
              fontWeight:'600',
              fontSize:'16px',
              width:'90%'

            }}
             >
              {triggersDesc.title[trigger.key] }
             <p style={{              fontWeight:'500',
              fontSize:'12px',}}> {triggersDesc.description[trigger.key] }</p>
             </div>
          </div>
        })}
       </div>
}
       <div dir="rtl" className="d-flex flex-row col-12 mt-3 px-2"><Button  onClick={()=>{setopenCustom(false)}} type='primary'>Confirm</Button></div>
      </Modal>
    </>
  );
}

export default NotificationSetting;
