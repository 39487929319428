import { useEffect, useState, useRef } from "react";
import { fetchData, putData } from "../../endpoints";
import "./style.scss";
import "react-accessible-accordion/dist/fancy-example.css";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { useMobile } from "../../hooks";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Button, Input, Modal, Space, Spin, Table } from "antd";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const Forms = ({ patientId }) => {
  const [assignForms, setAssignForms] = useState([]);
  const [otherForms, setOtherForms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const searchInput = useRef(null);
  const isMobile = useMobile();
  async function fetchForms() {
    setIsLoading(true);
    var res = await fetchData("admin/patient/" + patientId + "/forms");
    if (res.status === 200) {
      setAssignForms(res.data.date);
    }
    var res2 = await fetchData(
      `admin/form/?sortKey=${"priority"}&sortValue=${1}&perPage=${300}&page=${1}`
    );
    if (res2.status === 200) {
      var temp = res2.data.data;
      temp.map((f, i) => {
        f.children.map((form, j) => {
          if (form.children == []) {
            delete form.children;
          }
          temp[i].children[j] = { ...temp[i].children[j], key: j * 5 + 1 };
        });
        temp[i] = { ...temp[i], key: i };
      });
      temp = temp.filter((f) => {
        return !f.deletedAt;
      });
      setOtherForms(
        temp.filter((f, i) => {
          let is = false;
          if (assignForms.length > 0) {
            assignForms.map((a, i) => {
              assignForms[i].children.forEach((element, j) => {
                if (element.id == f.id) is = true;
              });
            });
          }
          return !is;
        })
      );
    }
    setIsLoading(false);
  }
  useEffect(() => {
    fetchForms();
  }, [patientId]);
  const assignForm = async (form) => {
    var res = await putData(`admin/form/${form.id}/share`, {
      patientId: patientId,
      level: "submit_and_edit",
    });
    if (res.status === 200) {
      await fetchForms();
    } else {
      Modal.error({
        title: res,
      });
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {}
            }
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: isMobile ? "55%" : "35%",
      ...getColumnSearchProps("title"),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "20%",
      responsive: ["md"],
      ...getColumnSearchProps("code"),
    },
    {
      title: "Visibility",
      dataIndex: "visibility",
      key: "visibility",
      responsive: ["md"],
      width: "15%",
    },
    privilage.indexOf(access.patientManegment.form.assignForm) > -1 && {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <div style={{ marginRight: "15px" }} dir="rtl">
          <span
            style={{
              color: "rgba(46, 108, 223, 1)",
              textAlign: "right",
              cursor: "pointer",
            }}
            onClick={() => {
              assignForm(u);
            }}
          >
            Assign Form
          </span>
        </div>
      ),
    },
  ];

  return (
    <div dir="ltr" className="w-100">
      <div
        className={
          isMobile
            ? "forms d-flex flex-column py-2  px-3"
            : "forms d-flex flex-column gap-3 py-4 px-3"
        }
        style={isMobile ? { width: "100%" } : { width: "75%" }}
      >
        <h1 style={isMobile ? { fontSize: "16px" } : { fontSize: "24px" }}>
          Forms
        </h1>
        <div>
          <h2 style={isMobile ? { fontSize: "12px" } : { fontSize: "24px" }}>
            Assigned Forms
          </h2>
          <div className="line" />
          {isLoading ? (
            <Spin
              style={{ marginTop: "20px", marginBottom: "40px" }}
              tip="Loading"
              size={isMobile ? "small" : "large"}
            >
              <div className="content" />
            </Spin>
          ) : (
            assignForms.map((form) => {
              return form.children ? FormCard(form, patientId, isMobile) : null;
            })
          )}
        </div>
        <div>
          <h2
            style={
              isMobile
                ? { fontSize: "12px", marginTop: "20px" }
                : { fontSize: "24px" }
            }
          >
            Other Forms
          </h2>
          <div className="line" />
          {isLoading ? (
            <Spin
              style={{ marginTop: "20px" }}
              tip="Loading"
              size={isMobile ? "small" : "large"}
            >
              <div className="content" />
            </Spin>
          ) : (
            <div className=" d-flex flex-column gap-3 ">
              <Table
                loading={isLoading}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={otherForms}
                size="small"
                style={{ width: "100%" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const FormCard = (form, patientId, isMobile) => {
  return (
    <Accordion
      allowZeroExpanded={true}
      allowMultipleExpanded={true}
      style={{ marginTop: "20px" }}
      // preExpanded={"0"}
    >
      <AccordionItem uuid={"0"}>
        <AccordionItemHeading>
          <AccordionItemButton>
            {" "}
            <div className="form-card__title">
              <h5 style={isMobile ? { fontSize: "10px" } : {}}>{form.title}</h5>
            </div>
            <div className="form-card__divider" />
            <div className="form-card__desc">
              <h6>
                {form.description.substr(0, 35)}
                {form.description.length > 35 ? "..." : ""}
              </h6>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {ChildForm(form, patientId, isMobile)}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};
const ChildForm = (parent, patientId, isMobile) => {
  return (
    <div className=" d-flex flex-column gap-3 ">
      {parent.children.map((form) => {
        return ChildFormCard(form, isMobile);
      })}
    </div>
  );
};

const ChildFormCard = (form, isMobile) => {
  return (
    <div className="form-card" id={form.code} style={{ height: "80px" }}>
      <div
        className="form-card__title d-flex flex-row gap-2 px-4"
        style={{ justifyContent: "flex-start", width: "75%" }}
      >
        <h6 style={{ textAlign: "left" }}>{form.title}</h6>
      </div>
      {form.submission ? (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginTop: "4px", marginLeft: "10%" }}
        >
          <path
            d="M8 16.0001C3.58174 16.0001 0 12.4183 0 8.00006C0 3.58185 3.58179 6.10352e-05 8 6.10352e-05C12.4182 6.10352e-05 16 3.58185 16 8.00006C16 12.4183 12.4182 16.0001 8 16.0001ZM5.01709 7.80567C4.6729 7.48062 4.13041 7.49616 3.80536 7.84015C3.4803 8.18434 3.49585 8.72684 3.84004 9.05189L6.41136 11.4806C6.77907 11.8277 7.36601 11.7829 7.67652 11.3835L11.6766 6.24065C11.9672 5.86696 11.8998 5.32845 11.5261 5.03769C11.1525 4.74711 10.614 4.81447 10.3234 5.18816L6.90263 9.58627L5.01709 7.80567Z"
            fill="#248C00"
          />
        </svg>
      ) : (
        <div className="d-flex flex-row gap-3 w-25"></div>
      )}
    </div>
  );
};
export default Forms;
