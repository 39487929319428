import React from "react";
import {
  fetchData,
  putData,
  putFormData,
  postData,
  deleteData,
  postFormData,
} from "../../endpoints";
import { useState, useEffect, useRef, useCallback } from "react";
import { useMobile } from "../../hooks";
import {
  Input,
  Button,
  Space,
  Table,
  Modal,
  Form,
  Select,
  Tag,
  Spin,
} from "antd";
import Styles from "./Styles.module.scss";
import { FileUploader } from "react-drag-drop-files";
import {
  SearchOutlined,
  PlusOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { render } from "react-dom";
import ReactQuill, { Quill } from "react-quill";

const { TextArea } = Input;

function Articles() {
  const isMobile = useMobile();
  const [modal, contextHolder] = Modal.useModal();
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [viewArticle, setViewArticle] = useState(false);
  const [addArticle, setAddArticle] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedArticle, SetselectedArticle] = useState({});
  const [OpenShowArticle, setOpenShowArticle] = useState(false);
  const [SearchUserModal, setSearchUserModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [users, setUsers] = useState([]);
  const [data, setdata] = useState([]);
  const [text, settext] = useState("");
  const searchInput = useRef(null);
  const quillRef = useRef(null);

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "html",
  ];

  const insertToEditor = useCallback((url) => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    if (range) {
      editor.insertEmbed(range.index, "image", url);
    }
  }, []);
  const getUsers = async (page = 1, reload = false) => {
    setIsLoading(true);
    var temp = users;
    if (reload) {
      temp = [];
    }
    if (users.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        "admin/user/?deletedAt=false&perPage=15&page=" + page
      );
      res.data.data.map((tempdata, index) => {
        tempdata["key"] = index;
      });

      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setUsers(
            temp
              .filter((a, i) => {
                return a.email;
              })
              .concat([...res.data.data])
          );
        } else {
          setUsers(
            temp
              .filter((a, i) => {
                return a.email;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setIsLoading(false);
  };

  const handleSearchUser = async (selectedKeys, confirm, dataIndex) => {
    setIsLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      await getUsers();
    } else {
      let search = "";
      if (dataIndex == "name") search = "firstName";
      else if (dataIndex == "phone") search = "phoneNumber";
      else search = "email";
      var res = await fetchData(`admin/user/?${search}=` + selectedKeys[0]);
      if (res.status == 200) {
        res.data.data.map((tempdata, index) => {
          tempdata["key"] = index;
        });
        setUsers(res.data.data);
      }
    }
    setIsLoading(false);
  };

  const handleResetUser = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    await getUsers(1, true);
  };
  const getColumnSearchPropsUser = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchUser(selectedKeys, confirm, dataIndex)
          }
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchUser(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetUser(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const user_columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "",
      width: "25%",
      onCell: (record) => {
        return {
          onClick: () => {
            okUserModal(record.id);
          },
        };
      },
      ...getColumnSearchPropsUser("name"),
      render: (record) => (
        <h2 className="name-table">
          {record.firstName + " " + record.lastName}
        </h2>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "35%",
      ...getColumnSearchPropsUser("email"),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ...getColumnSearchPropsUser("phone"),
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
      // console.log(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const okUserModal = async (id = null) => {
    console.log(id);
    setIsLoading(true);
    var res;
    if (id) {
      res = await fetchData(`admin/blog/post/?authorId=${id}`);
    } else res = await fetchData(`admin/blog/post/?perPage=15`);

    // console.log(res);
    setdata(res.data.data);
    setSearchUserModal(false);
    setSelectedRowKeys(false);
    setIsLoading(false);
  };

  const handleImageUpload = useCallback(() => {
    // setIsLoading(prev=>!prev);
    const temp = localStorage.getItem("atricleid");
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    // console.log(id)

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("file", file);
      // console.log(selectedArticle);

      // console.log(temp);

      // const formData = new FormData();
      // formData.append("file", selectedArticle.cover);

      var res = await postFormData(
        `admin/blog/post/${temp}/attachment`,
        formData
      );

      // console.log(res);
      // console.log(formData);
      // const response = await fetch("https://example.com/upload-image", {
      //   method: "POST",
      //   body: formData,
      // });
      // const data = await response.json();
      const imageUrl = res.data.data.url;
      insertToEditor(imageUrl);
    };
    // setIsLoading(false);
  }, [insertToEditor]);

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
      ],
      handlers: {
        image: handleImageUpload,
      },
    },
  };

  const handleChange = useCallback((html) => {
    settext(html);
    // SetselectedArticle({ ...selectedArticle, body: html });
  }, []);

  useEffect(() => {
    // form.resetFields();
    getUsers();

    fetchArticles();
  }, []);

  const convertStringToHTML = (htmlString) => {
    let element = document.getElementById("myElement");
    if (element) element.innerHTML = htmlString;
  };

  useEffect(() => {
    convertStringToHTML(text);
  }, [selectedArticle]);

  async function fetchArticles(page = 1, isReload = false) {
    var temp = isReload ? [] : data;
    setIsLoading(true);
    if (data.length < (page - 1) * 15 + 2 || isReload) {
      var res = await fetchData("admin/blog/post/?perPage=15&page=" + page);
      res.data.data.map((tempdata, index) => {
        tempdata["key"] = index;
      });

      //   console.log(res);
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setdata(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat(res.data.data)
          );
        } else {
          setdata(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([
                ...res.data.data,
                { createdAt: new Date().toString(), locationTypes: [] },
              ])
          );
        }
      } else {
        Modal.error({
          title: res,
        });
      }
    }

    setIsLoading(false);
  }

  async function deleteArticle(u) {
    setIsLoading(true);
    var res = await deleteData(`admin/blog/post/${u.id}`);
    if (res.status == 200) {
      Modal.success({ title: "article have been successfully deleted." });
    }
    fetchArticles(1, true);
    setIsLoading(false);
  }

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    setIsLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setdata([]);
      await fetchArticles(1, true);
    } else {
      let search = "";
      // if (dataIndex == "authorId") search = "authorId";
      if (dataIndex == "title") search = "title";
      if (dataIndex == "abstract") search = "abstract";
      if (dataIndex == "publishedAt") search = "publishedAt";

      var res = await fetchData(
        `admin/blog/post/?${search}=` + selectedKeys[0]
      );
      res.data.data.map((tempdata, index) => {
        tempdata["key"] = index;
      });
      if (res.status == 200) {
        setdata(res.data.data);
      }
    }
    setIsLoading(false);
  };
  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdata([]);
    await fetchArticles(1, true);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Author</div>
          <div style={{ paddingLeft: "5px", cursor: "pointer" }}>
            <SearchOutlined
              style={{ fontSize: "12px" }}
              onClick={() => {
                setSearchUserModal(true);
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "authorId",
      key: "authorId",
      //   width: "15%",
      // ...getColumnSearchProps("authorId"),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title"),
    },
    {
      title: "Abstract",
      dataIndex: "abstract",
      key: "abstract",
      ...getColumnSearchProps("abstract"),
      render: (u) => <div>{u.length > 100 ? `${u.slice(0, 97)}...` : u}</div>,
    },
    {
      title: "Published At",
      dataIndex: "publishedAt",
      key: "publishedAt",
      render: (u) => (
        <div>{u ? new Date(u).toLocaleDateString() : "Not Published"}</div>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) =>
        u.publishedAt ? (
          <a
            onClick={async () => {
              setIsLoading(true);
              var res = await fetchData(`admin/blog/post/${u.id}`);
              console.log(res);
              if (res.status == 200) {
                SetselectedArticle(res.data.data);
                settext(res.data.data.body);
                // localStorage.setItem("atricleid",res.data.data.id)
                setViewArticle(true);
              }
              setIsLoading(false);
            }}
            style={{ color: "black" }}
          >
            View
          </a>
        ) : (
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={async () => {
              modal.confirm({
                title: "Are you sure you want to publish this article?",
                icon: <ExclamationCircleOutlined />,
                content: "",
                okText: "Confirm",
                cancelText: "Cancel",
                onOk: async () => {
                  var res = await putData(`admin/blog/post/${u.id}/publish`);
                  if (res.status == 200) {
                    Modal.success({
                      content: "article published Successfully",
                    });
                  }
                  fetchArticles(1, true);
                },
              });
            }}
          >
            Publish
          </div>
        ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <div
          style={{ color: "black", cursor: "pointer" }}
          onClick={async () => {
            setIsLoading(true);
            var res = await fetchData(`admin/blog/post/${u.id}`);
            console.log(res);
            if (res.status == 200) {
              SetselectedArticle(res.data.data);
              settext(res.data.data.body);
              localStorage.setItem("atricleid", res.data.data.id);
              setAddArticle(true);
            }
            setIsLoading(false);
          }}
        >
          Update
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <a
          onClick={() => {
            Modal.confirm({
              title: "Are you sure you want to delete this article?",
              icon: <ExclamationCircleFilled />,
              okText: "Yes",
              okType: "danger",
              cancelText: "No",
              onOk() {
                deleteArticle(u);
              },
              onCancel() {},
            });
          }}
          style={{ color: "red" }}
        >
          Delete
        </a>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div className={Styles.wrapp}>
        <div
          style={
            isMobile
              ? { width: "87vw", padding: "0px 20px" }
              : { padding: "0px 55px" }
          }
        >
          {selectedArticle.id || addArticle == true ? (
            <div>
              <svg
                style={
                  isMobile
                    ? { marginTop: "20px", marginLeft: "-15px" }
                    : { marginTop: "20px", marginLeft: "-30px" }
                }
                onClick={() => {
                  setAddArticle(false);
                  SetselectedArticle({});
                  settext("");
                }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.8016 11.3956H2.6553L10.0228 4.01421C10.2548 3.78219 10.2548 3.40603 10.0228 3.17401C9.79085 2.942 9.41481 2.942 9.18286 3.17401L0.783422 11.576C0.667448 11.6847 0.601562 11.8369 0.601562 11.996C0.601562 12.1552 0.667451 12.3074 0.783422 12.4161L9.18286 20.8181C9.2916 20.9341 9.44374 21 9.60283 21C9.76193 21 9.91406 20.9341 10.0228 20.8181C10.1388 20.7093 10.2047 20.5571 10.2047 20.398C10.2047 20.2388 10.1388 20.0867 10.0228 19.9779L2.6553 12.5965H22.8016C23.1329 12.5965 23.4016 12.3278 23.4016 11.9964C23.4016 11.665 23.1329 11.3963 22.8016 11.3963V11.3956Z"
                  fill="#18324E"
                />
              </svg>

              <p
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                {selectedArticle.id ? "Update " : "Add "}
                Article
              </p>
              <div style={{ width: "100%" }}>
                <div
                  className="col-12"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      padding: "15px 0px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ color: "red" }}>*</div>
                    Title:
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      padding: " 15px 0px",
                    }}
                    className="col-11"
                  >
                    <Input
                      className="col-12"
                      value={selectedArticle.title}
                      onChange={(e) => {
                        SetselectedArticle({
                          ...selectedArticle,
                          title: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={isMobile ? "col-10" : "col-12"}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      padding: "15px 0px",
                    }}
                  >
                    Abstract:
                  </div>
                  <div
                    className="col-11"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      padding: "15px 0px",
                    }}
                  >
                    <TextArea
                      className="col-12"
                      rows={4}
                      value={selectedArticle.abstract}
                      onChange={(e) => {
                        SetselectedArticle({
                          ...selectedArticle,
                          abstract: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  className="col-6 gap-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      padding: "15px 0px",
                    }}
                  >
                    Cover:
                  </div>
                  <FileUploader
                    required
                    label=" "
                    handleChange={(file) => {
                      SetselectedArticle({ ...selectedArticle, cover: file });
                    }}
                    name="file"
                    types={["JPG", "JPEG", "PNG"]}
                    maxSize={2}
                    children={
                      <Button type="default">
                        {!selectedArticle.cover
                          ? "Upload"
                          : "string" == typeof selectedArticle.cover
                          ? "Upload New Photo"
                          : "Uploaded Successfully!"}
                      </Button>
                    }
                  />
                  {!isMobile &&
                  selectedArticle.cover &&
                  "string" == typeof selectedArticle.cover ? (
                    <img
                      src={selectedArticle.cover}
                      style={
                        isMobile
                          ? { maxHeight: "100px", maxWidth: "200px" }
                          : { maxHeight: "200px", maxWidth: "400px" }
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
                {isMobile &&
                selectedArticle.cover &&
                "string" == typeof selectedArticle.cover ? (
                  <img
                    src={selectedArticle.cover}
                    style={
                      isMobile
                        ? { maxHeight: "100px", maxWidth: "200px" }
                        : { maxHeight: "200px", maxWidth: "400px" }
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
              {selectedArticle.id && (
                <div style={{ marginBottom: "80px" }}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      padding: "15px 0px 0px 0px",
                      marginBottom: "20px",
                    }}
                  >
                    Body:
                  </div>
                  <hr />
                  <ReactQuill
                    ref={quillRef}
                    value={text}
                    formats={formats}
                    modules={modules}
                    onChange={handleChange}
                  />
                </div>
              )}
              {selectedArticle.id && (
                <div
                  className="col-8 align-items-center"
                  style={{
                    textAlign: "center",
                    position: "fixed",
                    bottom: "30px",
                  }}
                >
                  <Button
                    loading={isLoading}
                    style={{
                      padding: "5px 25px",
                      borderRadius: "5px",
                      marginRight: "5px",
                      backgroundColor: "white",
                      color: "rgba(46, 108, 223, 1)",
                      border: "solid 1px rgba(46, 108, 223, 1)",
                    }}
                    onClick={async () => {
                      setIsLoading(true);

                      if (!selectedArticle.title) {
                        Modal.error({
                          title: "please enter Title!",
                        });
                        setIsLoading(false);

                        return;
                      }
                      if (selectedArticle.id) {
                        // console.log(text)
                        let temp = {
                          authorId: selectedArticle.authorId,
                          title: selectedArticle.title,
                          body: text,
                          abstract: selectedArticle.abstract,
                        };
                        var res = await putData(
                          `admin/blog/post/${selectedArticle.id}`,
                          temp
                        );

                        if ("string" != typeof selectedArticle.cover) {
                          const formData = new FormData();
                          formData.append("file", selectedArticle.cover);

                          var res = await putFormData(
                            `admin/blog/post/${selectedArticle.id}/cover`,
                            formData
                          );
                        }
                        SetselectedArticle({});
                        settext("");
                        // localStorage.setItem("atricleid",res.data.data.id)
                        localStorage.removeItem("articleid");
                        setAddArticle(false);
                        fetchArticles(1, true);
                        setIsLoading(false);
                      } else {
                        // console.log(text)

                        let temp = {
                          authorId: selectedArticle.authorId,
                          title: selectedArticle.title,
                          body: text,
                          abstract: selectedArticle.abstract,
                        };
                        var res = await postData(`admin/blog/post`, temp);
                        // console.log(res);
                        if (typeof selectedArticle.cover != String) {
                          const formData = new FormData();
                          // console.log(newService.cover)
                          formData.append("file", selectedArticle.cover);
                          //   console.log("meoooooooooo");
                          //   console.log(selectedArticle.cover);

                          var res2 = await putFormData(
                            `admin/blog/post/${res.data.data.id}/cover`,
                            formData
                          );
                          //   console.log(res2);

                          //   console.log(res2)
                        }
                        SetselectedArticle({});
                        settext("");
                        localStorage.removeItem("articleid");

                        setAddArticle(false);
                        fetchArticles(1, true);
                        setIsLoading(false);
                      }
                    }}
                  >
                    Save Draft
                  </Button>
                  <Button
                    loading={isLoading}
                    style={{
                      padding: "5px 25px",
                      marginLeft: "5px",
                      borderRadius: "5px",
                      backgroundColor: "rgba(46, 108, 223, 1)",
                      color: "white",
                    }}
                    onClick={async () => {
                      setIsLoading(true);

                      if (!selectedArticle.title) {
                        Modal.error({
                          title: "please enter Title!",
                        });
                        setIsLoading(false);
                        return;
                      }

                      if (selectedArticle.id) {
                        let temp = {
                          authorId: selectedArticle.authorId,
                          title: selectedArticle.title,
                          body: text,
                          abstract: selectedArticle.abstract,
                        };
                        var res = await putData(
                          `admin/blog/post/${selectedArticle.id}`,
                          temp
                        );

                        if (typeof selectedArticle.cover != String) {
                          const formData = new FormData();

                          formData.append("file", selectedArticle.cover);

                          var res = await putFormData(
                            `admin/blog/post/${selectedArticle.id}/cover`,
                            formData
                          );
                          //   console.log(res);
                        }
                        var res = await putData(
                          `admin/blog/post/${selectedArticle.id}/publish`
                        );
                        SetselectedArticle({});
                        settext("");
                        localStorage.removeItem("articleid");

                        setAddArticle(false);
                        fetchArticles(1, true);
                      } else {
                        let temp = {
                          authorId: selectedArticle.authorId,
                          title: selectedArticle.title,
                          body: text,
                          abstract: selectedArticle.abstract,
                        };
                        var res = await postData(`admin/blog/post`, temp);

                        if (typeof selectedArticle.cover != String) {
                          const formData = new FormData();

                          formData.append("file", selectedArticle.cover);
                          var res2 = await putFormData(
                            `admin/blog/post/${res.data.data.id}/cover`,
                            formData
                          );
                          //   console.log(res2);
                        }

                        var res = await putData(
                          `admin/blog/post/${res.data.data.id}/publish`
                        );
                      }

                      SetselectedArticle({});
                      settext("");
                      localStorage.removeItem("articleid");

                      setAddArticle(false);
                      fetchArticles(1, true);
                      setIsLoading(false);
                    }}
                  >
                    Publish
                  </Button>
                </div>
              )}
              {!selectedArticle.id && (
                <div
                  className="col-8 align-items-center"
                  style={{
                    textAlign: "center",
                    position: "fixed",
                    bottom: "30px",
                  }}
                >
                  <Button
                    loading={isLoading}
                    style={{
                      padding: "5px 25px",
                      marginLeft: "5px",
                      borderRadius: "5px",
                      backgroundColor: "rgba(46, 108, 223, 1)",
                      color: "white",
                    }}
                    onClick={async () => {
                      setIsLoading(true);

                      if (!selectedArticle.title) {
                        Modal.error({
                          title: "please enter Title!",
                        });
                        setIsLoading(false);
                        return;
                      }

                      let temp = {
                        authorId: selectedArticle.authorId,
                        title: selectedArticle.title,
                        body: text,
                        abstract: selectedArticle.abstract,
                      };
                      var res = await postData(`admin/blog/post`, temp);

                      if (typeof selectedArticle.cover != String) {
                        const formData = new FormData();

                        formData.append("file", selectedArticle.cover);
                        var res2 = await putFormData(
                          `admin/blog/post/${res.data.data.id}/cover`,
                          formData
                        );
                      }

                      SetselectedArticle({
                        ...selectedArticle,
                        id: res.data.data.id,
                      });

                      localStorage.setItem("atricleid", res.data.data.id);

                      setIsLoading(false);
                    }}
                  >
                    Next
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className={Styles.Events_wrapp}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    className={`${"forms d-flex flex-column gap-3 py-3 w-100 mb-3"}`}
                    style={
                      isMobile
                        ? { fontSize: "18px", fontWeight: "bold" }
                        : {
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginLeft: "-30px",
                          }
                    }
                  >
                    Articles
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <Spin size="large" />
                </div>
              ) : (
                <Table
                  scroll={{ x: "auto" }}
                  loading={isLoading}
                  // rowSelection={rowSelection}
                  columns={columns}
                  dataSource={data}
                  pagination={{
                    onChange: async (page) => {
                      await fetchArticles(page);
                    },
                    // total: 50,
                    pageSize: 15,
                  }}
                  footer={() => {
                    return (
                      <div
                        //   onClick={() => {
                        //     setOpenaddevent(true);
                        //   }}
                        className="d-flex fle-row gap-2 px-4 align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <PlusOutlined
                          style={{
                            color: "rgba(46, 108, 223, 1)",
                          }}
                        />
                        <h6
                          style={{
                            color: "rgba(46, 108, 223, 1)",
                            marginBottom: "0",
                          }}
                          onClick={() => {
                            SetselectedArticle({});
                            settext("");
                            localStorage.removeItem("articleid");
                            setAddArticle(true);
                          }}
                        >
                          Add Article
                        </h6>
                      </div>
                    );
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        style={{ maxHeight: "78vh" }}
        open={SearchUserModal}
        title="Search User"
        width={1000}
        onOk={() => {
          okUserModal(users[selectedRowKeys[0]].id);
          // okUserModal(2);
        }}
        onCancel={() => {
          setSearchUserModal(false);
        }}
      >
        <div
          style={
            isMobile
              ? { minWidth: "80vw" }
              : { maxHeight: "75vh", overflowY: "scroll" }
          }
        >
          <Table
            scroll={{ x: "auto" }}
            loading={isLoading}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={user_columns}
            dataSource={users}
            size="small"
            style={isMobile ? {} : { width: "90%", margin: "40px" }}
            pagination={{
              onChange: async (page) => {
                await getUsers(page);
              },
              // total: 50,
              pageSize: 15,
            }}
          />
        </div>
      </Modal>
    </>
  );
}

export default Articles;
