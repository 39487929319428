const full = [
  "upload_template_attachment",
  "update_user_notification_trigger",
  "index_user_notification_trigger",
  "get_purchase_invoice",
  "send_template",
  "index_template",
  "delete_template",
  "update_template",
  "create_template",
  "checkout_patient_payment",
  "update_user_notification_channel",
  "index_user_notification_channel",
  "delete_notification_trigger",
  "update_notification_trigger",
  "create_notification_trigger",

  "show_notification_trigger",
  "index_notification_trigger",
  "delete_user_assignment",
  "index_user_assignments",
  "show_user_assignment",
  "create_user_assignment",
  "show_contact_us_request",
  "index_contact_us_requests",
  "create_new_version_for_service",
  "duplicate_service",
  "delete_appointment_translator",
  "update_appointment_translator",
  "create_appointment_translator",
  "show_appointment_translator",
  "index_appointment_translator",
  "index_purchase",
  "index_purchase_payments",
  "delete_blog_post_cover",
  "upload_blog_post_cover",
  "index_blog_post",
  "show_blog_post",
  "show_blog_post_attachment",
  "delete_blog_post_attachment",
  "update_blog_post_attachment",
  "update_blog_post",
  "create_blog_post",
  "check_purchase_blocking_conditions",
  "cancel_purchase",
  "delete_device",
  "index_device",
  "",
  "delete_device",
  "index_device",
  "delete_privilege_from_role",
  "add_privilege_to_role",
  "delete_role",
  "update_role",
  "index_role",
  "show_role",
  "create_role",
  "delete_privilege",
  "update_privilege",
  "index_privilege",
  "show_privilege",
  "create_privilege",
  "delete_report_voice",

  "upload_report_voice",
  "show_report_voice",
  "run_report_section",
  "delete_report_section",
  "update_report_section",
  "index_report_section",
  "show_report_section",
  "create_report_section",
  "delete_report_question",
  "update_report_question",
  "index_report_question",
  "show_report_question",
  "create_report_question",
  "quality_control_report_command",
  "run_report_command",

  "delete_report_command",
  "update_report_command",
  "index_report_command",
  "show_report_command",
  "create_report_command",
  "export_report_benchmark",
  "index_report_benchmark",
  "show_report_benchmark",
  "upload_report_final_result",
  "export_report",
  "run_report",
  "import_report",
  "update_report_result",
  "clone_report",
  "index_report_template",

  "delete_report",
  "update_report",
  "index_report",
  "show_report",
  "create_report",
  "index_purchase_reply",
  "index_purchase_reply",
  "generate_purchase_appointment_step_url",
  "read_purchase_reply",
  "index_purchase_reply",
  "delete_purchase_reply",
  "update_purchase_reply",
  "create_purchase_reply",
  "decline_purchase_step",
  "approve_purchase_step",

  "update_purchase_step_status",
  "approve_purchase",
  "show_purchase",
  "delete_service_type",
  "update_service_type",
  "index_service_type",
  "show_service_type",
  "create_service_type",
  "delete_service_step_condition",
  "index_service_step_condition",
  "create_service_step_condition",
  "delete_service_step_block",
  "index_service_step_block",
  "create_service_step_block",
  "delete_service_step",
  "update_service_step",
  "index_service_step",
  "show_service_step",
  "create_service_step",
  "publish_service",
  "upload_service_cover",
  "purchase_service",
  "delete_service",
  "update_service",
  "index_service",
  "show_service",
  "create_service",
  "delete_comment_watcher",
  "index_comment_watcher",
  "show_comment_watcher",
  "create_comment_watcher",
  "delete_comment_attachment",
  "update_comment_attachment",
  "index_comment_attachment",
  "download_comment_attachment",
  "show_comment_attachment",
  "create_comment_attachment",
  "delete_comment_assignee",
  "index_comment_assignee",
  "show_comment_assignee",
  "create_comment_assignee",
  "delete_comment",
  "update_comment",
  "index_comment",
  "show_comment",

  "create_comment",
  "delete_transaction",
  "update_transaction",
  "show_user_credit",
  "index_transaction",
  "create_transaction",
  "update_payment",
  "delete_patient_photo",
  "upload_patient_photo",
  "delete_patient_referral_by",
  "update_patient_referral_by",
  "create_patient_referral_by",
  "delete_patient_teacher",
  "update_patient_teacher",
  "create_patient_teacher",
  "update_patient",
  "delete_patient_service_type",
  "update_patient_service_type",
  "create_patient_service_type",
  "delete_patient_emergency_contact",
  "update_patient_emergency_contact",
  "create_patient_emergency_contact",
  "index_patient_form",
  "verify_patient",
  "enable_patient",
  "disable_patient",
  "delete_patient",
  "index_patient",
  "show_patient",
  "create_patient",
  "delete_document_category",

  "update_document_category",
  "index_document_category",
  "show_document_category",
  "create_document_category",
  "delete_document",
  "update_document",
  "index_document",
  "show_document",
  "create_document",
  "resend_sign_form_request",
  "preview_sign_form_request",
  "index_box_sign_templates",
  "delete_sign_form",
  "update_sign_form",
  "index_sign_form",

  "show_sign_form",
  "create_sign_form",
  "delete_form_submission",
  "update_form_submission",
  "index_form_submission",
  "show_form_submission",
  "create_form_submission",
  "delete_form_access",
  "update_form_access",
  "index_form_access",
  "show_form_access",
  "create_form_access",
  "index_jotform_form",
  "share_form",
  "delete_form",

  "update_form",
  "index_form",
  "show_form",

  "create_form",
  "delete_appointment",
  "update_appointment",
  "index_appointment",
  "show_appointment",
  "create_appointment",
  "index_calendly_event",
  "index_calendly_user",
  "delete_appointment_event",
  "update_appointment_event",
  "index_appointment_event",
  "show_appointment_event",

  "create_appointment_event",
  "index_otp",
  "update_user_profile",
  "index_user_profile",
  "show_user_profile",
  "create_user_profile",
  "enable_user",
  "disable_user",
  "delete_user",
  "update_user",
  "index_user",
  "show_user",
  "create_user",
];
export default full;
