import "./styles.scss";
import Step from "./Step";
import {
  Button,
  Input,
  Modal,
  Form,
  Checkbox,
  InputNumber,
  Spin,
  Slider,
  message,
} from "antd";

import { postData, deleteData, putData, putFormData } from "../../endpoints";
import { useMobile } from "../../hooks/useMobile";
import { ExclamationCircleFilled } from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";
import { FileUploader } from "react-drag-drop-files";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
import Cropper from "react-easy-crop";
const privilage = Cookies.get("privilege") ?? full;
const AddService = ({
  newService,
  setNewService,
  setIsOpen,
  serviceTypes,
  isLoading,
  event,
  setIsLoading,
  fetchServices,
  setAddCond,
  setStep,
  loading2,
  setLoading2,
  loading3,
  setLoading3,
  view,
}) => {
  const [myForm] = Form.useForm();
  const isMobile = useMobile();
  const { confirm } = Modal;
  const [html, setHtml] = useState(newService.description);
  const convertStringToHTML = (htmlString) => {
    let element = document.getElementById("myElement");
    if (element) element.innerHTML = htmlString;
  };

  const [imageUrl, setImageUrl] = useState(
    newService.cover ? (
      <img
        style={{ marginLeft: "26%", width: "30%", height: "auto" }}
        src={`https://allbrains-dashboard-stg-public.s3.amazonaws.com/${
          newService.cover
        }?${new Date().getMilliseconds}`}
      />
    ) : null
  );

  const [isCropping, setIsCropping] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const fileTypes = ["JPG", "JPEG", "PNG"];

  var modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  var formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const handleFileSelect = (file) => {
    if (file.size / 1024 / 1024 > 2) {
      message.error("Image must be smaller than 2MB!");
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImgSrc(reader.result);
      setIsCropping(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCropComplete = async () => {
    try {
      const croppedBlob = await getCroppedImg(
        imgSrc,
        croppedAreaPixels,
        "blob"
      );
      const croppedFile = new File([croppedBlob], "cropped_image.png", {
        type: "image/png",
        lastModified: new Date().getTime(),
      });

      setNewService({ ...newService, cover: croppedFile });
      setIsCropping(false);
      setImageUrl(null);
    } catch (error) {
      console.error("Crop error:", error);
      message.error("An error occurred while cropping the image.");
    }
  };

  async function getCroppedImg(imageSrc, crop, outputType = "dataURL") {
    const createImage = (url) =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = (error) => reject(error);
        image.src = url;
      });

    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    if (outputType === "blob") {
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/png");
      });
    } else {
      return canvas.toDataURL("image/png");
    }
  }

  function onChange(e) {
    convertStringToHTML(e);
    setHtml(e);
    setNewService({ ...newService, description: e });
  }

  const publishService = async (id) => {
    setIsLoading(true);
    var res = await putData(`admin/service/${id}/publish`);
    if (res.status == 200) {
      myForm.resetFields();
      setNewService({
        steps: [],
        price: [],
        isEdit: true,
      });
      fetchServices(1, true);
      setIsOpen(false);
      setIsLoading(false);
      Modal.success({ title: res.data.message });
    } else {
      setIsLoading(false);
      Modal.error({ title: res });
    }
  };
  const postStep = async (step, i) => {
    if (!step.title) {
      Modal.error({
        title: "Title is required. Please enter a descriptive title.",
      });
      return;
    }
    if (!step.type) {
      Modal.error({
        title:
          "Type is required. Please select a valid option from the dropdown.",
      });
      return;
    }
    if (!step.description) {
      Modal.error({
        title: "Details are required. Please provide more information.",
      });
      return;
    }

    setIsLoading(true);
    var temp2 = step;
    var temp1 = {
      ...temp2,
      serviceId: newService.id,
      startDays: temp2.startDays ?? 0,
      expireDays: temp2.expireDays ?? newService.activeDays,
    };
    if (
      step.type == "information" ||
      step.type == "payment" ||
      step.type == "document"
    ) {
      temp1 = { ...temp1, modelId: null };
    }

    var res = await postData("admin/service-step", temp1);
    if (res.status == 201) {
      var temp = newService.steps;
      temp[i] = { ...res.data.data, isEdit: false, blocks: step.blocks };
      setNewService({ ...newService, steps: temp });
      Modal.success({ title: res.data.message });
      setIsLoading(false);
    } else {
      Modal.error({ title: res });
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const editService = async () => {
    console.log(newService.types);
    if (!newService.title) {
      Modal.error({
        title: "Title is required. Please enter a name for your service.",
      });
      return;
    }
    if (!newService.types || newService.types.length < 1) {
      Modal.error({ title: "Please select at least one service type." });
      return;
    }

    setIsLoading(true);
    var m = newService;
    if (typeof newService.types[0] == "object") {
      m = {
        ...newService,
        types: newService.types.map((t) => {
          return t.serviceTypeId;
        }),
      };
    }
    if (m.activeDays < 1) {
      delete m.activeDays;
    }
    var res = await putData("admin/service/" + newService.id, m);
    if (res.status == 200) {
      if (newService.cover && typeof newService.cover != "string") {
        serviceCover(newService.id);
      }

      Modal.success({ title: res.data.message });
      setNewService({
        ...res.data.data,
        price: newService.price,
        steps: newService.steps,
        types: res.data.data.types.map((t) => {
          return t.serviceTypeId;
        }),
        isEdit: false,
      });
      setIsLoading(false);
      return true;
    } else {
      Modal.error({ title: res });
      setIsLoading(false);
      return false;
    }
  };
  const editStep = async (step, i) => {
    if (!step.title) {
      Modal.error({
        title: "Title is required. Please enter a descriptive title.",
      });
      return;
    }
    if (!step.type) {
      Modal.error({
        title:
          "Type is required. Please select a valid option from the dropdown.",
      });
      return;
    }
    if (!step.description) {
      Modal.error({
        title: "Details are required. Please provide more information.",
      });
      return;
    }

    setIsLoading(true);
    var temp1 = {
      ...step,
      startDays: step.startDays && step.startDays >= 0 ? step.startDays : 0,
      expireDays: step.expireDays ?? newService.activeDays,
    };

    if (
      step.type == "information" ||
      step.type == "payment" ||
      step.type == "document"
    ) {
      temp1 = { ...temp1, modelId: null };
    }
    var res = await putData("admin/service-step/" + step.id, {
      ...temp1,
      serviceId: newService.id,
    });
    if (res.status == 200) {
      var temp = newService.steps;
      temp[i] = { ...res.data.data, isEdit: false, blocks: temp[i].blocks };
      setNewService({ ...newService, steps: temp });
      Modal.success({ title: res.data.message });
    } else {
      Modal.error({ title: res });
    }
    setIsLoading(false);
  };
  const addService = async () => {
    console.log(newService.types);
    if (!newService.title) {
      Modal.error({
        title: "Title is required. Please enter a name for your service.",
      });
      return;
    }
    if (!newService.types || newService.types.length < 1) {
      Modal.error({ title: "Please select at least one service type." });
      return;
    }

    setIsLoading(true);
    var m = newService;
    if (m.activeDays < 1) {
      delete m.activeDays;
    }
    var res = await postData("admin/service", { ...m });
    if (res.status == 201) {
      if (newService.cover) {
        serviceCover(res.data.data.id);
      }
      Modal.success({ title: res.data.message });
      if (newService.steps.length > 0) {
        setNewService({ ...m, id: res.data.data.id, isEdit: false });
      } else {
        setNewService({
          ...res.data.data,
          price: [],
          steps: [],
          types: res.data.data.types.map((t) => {
            return t.serviceTypeId;
          }),
          isEdit: false,
        });
      }
      setIsLoading(false);
      return true;
    } else {
      Modal.error({ title: res });
      setIsLoading(false);
      return false;
    }
  };

  const serviceCover = async (id) => {
    const formData = new FormData();

    formData.append("file", newService.cover);
    var res2 = await putFormData(
      "admin/service/" + id + "/cover/upload",
      formData
    );
    if (res2.status == 200) {
      setImageUrl(
        <div>
          <img
            style={{ marginLeft: "26%", width: "30%", height: "auto" }}
            src={`https://allbrains-dashboard-stg-public.s3.amazonaws.com/${
              res2.data.data.cover
            }?${new Date().getMilliseconds}`}
          />
        </div>
      );
    }
    if (res2.status != 200) {
      Modal.error({ title: res2 });
    }
  };

  const deleteStep = async (step) => {
    setIsLoading(true);
    if (step.id) {
      var res = await deleteData(`admin/service-step/${step.id}`);
      if (res.status == 200) {
        var temp = newService.steps.filter((a) => {
          return a != step;
        });
        setNewService({ ...newService, steps: temp });
        setIsLoading(false);
        Modal.success({ title: res.data.message });
      } else {
        setIsLoading(false);
        Modal.error({ title: res });
      }
    } else {
      temp = newService.steps.filter((a) => {
        return a != step;
      });
      setNewService({ ...newService, steps: temp });
      setIsLoading(false);
      Modal.success({ title: "Service step deleted successfully." });
    }
    setIsLoading(false);
  };

  const showDeleteConfirm = async (step) => {
    confirm({
      title: "Are you sure remove this step?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteStep(step);
      },
      onCancel() {},
    });
  };

  return (
    <>
      <div
        className={`add-service d-flex flex-column gap-1 ${
          isMobile ? "px-3" : "px-5"
        } `}
      >
        {convertStringToHTML(html)}
        <div
          style={
            isMobile
              ? {
                  marginTop: "20px",
                  marginLeft: "0.2rem",
                  marginBottom: "30px",
                }
              : {
                  marginTop: "-35px",
                  marginLeft: "-15px",
                  marginBottom: "10px",
                }
          }
          className="d-flex flex-row gap-2 align-items-center"
        >
          <svg
            onClick={() => {
              if (newService.backTo == "view") {
                myForm.resetFields();

                fetchServices(1, true);
                setIsOpen(false);
                view(true);
                return;
              }
              myForm.resetFields();
              setNewService({
                steps: [],
                price: [],
                isEdit: true,
              });
              fetchServices(1, true);
              setIsOpen(false);
            }}
            width="24"
            height="18"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.8016 8.39557H2.6553L10.0228 1.01421C10.2548 0.78219 10.2548 0.40603 10.0228 0.174013C9.79085 -0.0580043 9.41481 -0.0580043 9.18286 0.174013L0.783422 8.57595C0.667448 8.68473 0.601562 8.83691 0.601562 8.99605C0.601562 9.15519 0.667451 9.30737 0.783422 9.41615L9.18286 17.8181C9.2916 17.9341 9.44374 18 9.60283 18C9.76193 18 9.91406 17.9341 10.0228 17.8181C10.1388 17.7093 10.2047 17.5571 10.2047 17.398C10.2047 17.2388 10.1388 17.0867 10.0228 16.9779L2.6553 9.59653H22.8016C23.1329 9.59653 23.4016 9.32781 23.4016 8.99639C23.4016 8.66498 23.1329 8.39625 22.8016 8.39625V8.39557Z"
              fill="#18324E"
            />
          </svg>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "500",
              paddingTop: "10px",
              color: "#18324E",
            }}
          >
            Back to{" "}
            {newService.backTo == "view"
              ? "service view page"
              : "services list"}
          </p>
        </div>

        {newService.editService ? (
          <h2>Edit Service</h2>
        ) : (
          <h2 style={{ marginBottom: "40px" }}> Add New Service</h2>
        )}
        <div
          style={{ justifyContent: "right", marginBottom: "-40px" }}
          className="d-flex flex-row w-100"
        >
          {newService.steps.length > 0 &&
            newService.id &&
            privilage.indexOf(access.serviceManegment.publish) > -1 && (
              <Button
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#2E6CDF",
                }}
                loading={isLoading}
                onClick={async () => {
                  await publishService(newService.id);
                }}
                type="text"
              >
                Publish
              </Button>
            )}
          {newService.id && (
            <Button
              style={{ fontSize: "14px", fontWeight: "700", color: "#4C4C4C" }}
              loading={isLoading}
              onClick={() => {
                myForm.resetFields();
                setNewService({
                  steps: [],
                  price: [],
                  isEdit: true,
                });
                fetchServices(1, true);
                setIsOpen(false);
              }}
              type="text"
            >
              Save Draft
            </Button>
          )}
        </div>
        <Form
          className="d-flex flex-column gap-3 py-3"
          labelCol={{ span: 6 }}
          size="large"
          form={myForm}
        >
          <div>
            <h3>Information</h3>
            <div className="h-line" />
            <div
              className={`col-12 d-flex ${
                isMobile ? "flex-column" : "flex-row gap-4"
              }`}
            >
              <div className="col-lg-6 col-12 px-2">
                <Form.Item
                  label={"Title"}
                  name="title"
                  rules={[{ required: true, message: "Please enter a title!" }]}
                >
                  <Input
                    defaultValue={newService.title}
                    // value={newService.title}
                    disabled={!newService.isEdit}
                    onChange={(e) => {
                      setNewService({ ...newService, title: e.target.value });
                    }}
                  />
                </Form.Item>

                <FormItem
                  label={"Types"}
                  name="types"
                  rules={[
                    {
                      required: true,
                      message: "Please select at least one service type.",
                    },
                  ]}
                  initialValue={
                    newService.types
                      ? newService.types.map((t) => {
                          if (t) return t.serviceTypeId;
                          else return 1;
                        })
                      : newService.id
                      ? [1]
                      : []
                  }
                >
                  <Checkbox.Group
                    disabled={!newService.isEdit}
                    options={serviceTypes.map((s) => {
                      return { value: s.id, label: s.title };
                    })}
                    className="d-flex flex-row gap-2"
                    onChange={(value) => {
                      setNewService({
                        ...newService,
                        types: value,
                      });
                    }}
                  ></Checkbox.Group>
                </FormItem>
              </div>
              <div className={`col-lg-5 col-12 ${isMobile ? "px-2" : ""}`}>
                <FormItem label={"Duration"} name={"duration"}>
                  <InputNumber
                    value={newService.activeDays}
                    min={0}
                    onChange={(e) => {
                      setNewService({
                        ...newService,
                        activeDays: e,
                      });
                    }}
                    keyboard={true}
                    disabled={!newService.isEdit}
                  />
                  <span style={{ marginLeft: "8px" }}> days </span>
                </FormItem>

                {/* <FormItem label={"Photo"}>
                  <FileUploader
                    disabled={!newService.isEdit}
                    required
                    label=" "
                    handleChange={(file) => {
                      setNewService({ ...newService, cover: file });
                      setImageUrl(null);
                    }}
                    name="file"
                    types={["JPG", "JPEG", "PNG"]}
                    maxSize={2}
                    children={
                      <Button className="col-12" type="default">
                        {!newService.cover
                          ? "Upload"
                          : typeof newService.cover == "string"
                          ? "Upload New Photo"
                          : "Uploaded Successfully!"}
                      </Button>
                    }
                  />
                </FormItem> */}

                {newService.isEdit && (
                  <FormItem label={"Photo"}>
                    <FileUploader
                      disabled={!newService.isEdit}
                      required
                      label=" "
                      handleChange={handleFileSelect}
                      name="file"
                      types={fileTypes}
                      maxSize={2}
                    >
                      <Button className="col-12" type="default">
                        {!newService.cover
                          ? "Upload"
                          : typeof newService.cover === "string"
                          ? "Upload New Photo"
                          : "Uploaded Successfully!"}
                      </Button>
                    </FileUploader>
                  </FormItem>
                )}

                {imageUrl ? imageUrl : <></>}
              </div>
            </div>
            <div
              className="col-10 gap-3 mb-4 px-2"
              style={{
                display: "flex",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>Description</div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                {newService.isEdit ? (
                  <ReactQuill
                    value={html}
                    formats={formats}
                    modules={modules}
                    onChange={onChange}
                  />
                ) : (
                  <div id="myElement" />
                )}
              </div>
            </div>
            <Button
              style={isMobile ? { marginLeft: "70%" } : { marginLeft: "80%" }}
              className="col-lg-2 col-3"
              type="primary"
              loading={isLoading && newService.isEdit}
              onClick={async () => {
                if (newService.isEdit && !newService.id) {
                  await addService();
                }
                if (newService.isEdit && newService.id) {
                  await editService();
                }
                if (!newService.isEdit) {
                  setNewService({ ...newService, isEdit: true });
                }
              }}
            >
              {newService.isEdit ? (!newService.id ? "Add" : "Save") : "Edit"}
            </Button>
          </div>
          {newService.id && (
            <div>
              <h3>Steps</h3>
              <div className="h-line" />

              {newService.steps ? (
                loading3 ? (
                  <Spin size="large" />
                ) : (
                  newService.steps.map((s, i) => {
                    return (
                      <>
                        <Step
                          newService={newService}
                          setNewService={setNewService}
                          setStep={setStep}
                          setAddCond={setAddCond}
                          i={i}
                          event={event}
                          postStep={postStep}
                          editStep={editStep}
                          showDeleteConfirm={showDeleteConfirm}
                          isLoading={isLoading}
                          loading2={loading2}
                          setLoading2={setLoading2}
                          isMobile={isMobile}
                          setLoading3={setLoading3}
                          loading3={loading3}
                          step={s}
                        />
                        {!loading3 &&
                          privilage.indexOf(access.serviceManegment.addStep) >
                            -1 &&
                          i != newService.steps.length - 1 && (
                            <>
                              {" "}
                              <svg
                                onClick={() => {
                                  setNewService({
                                    ...newService,
                                    steps: [
                                      ...newService.steps.slice(0, i + 1),
                                      {
                                        priority: i,
                                        isEdit: true,
                                      },
                                      ...newService.steps.slice(i + 1),
                                    ],
                                  });
                                }}
                                style={{ marginBottom: "15px" }}
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width="32"
                                  height="32"
                                  rx="4"
                                  fill="#2E6CDF"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M27.0302 14.9328H17.0671V4.96968C17.0671 4.37996 16.5891 3.90244 15.9999 3.90244C15.4102 3.90244 14.9327 4.37996 14.9327 4.96968V14.9328H4.96958C4.37987 14.9328 3.90234 15.4103 3.90234 16C3.90234 16.5897 4.37987 17.0672 4.96958 17.0672H14.9327V27.0303C14.9327 27.6195 15.4102 28.0976 15.9999 28.0976C16.5891 28.0976 17.0671 27.6195 17.0671 27.0303V17.0672H27.0302C27.6194 17.0672 28.0975 16.5897 28.0975 16C28.0975 15.4103 27.6194 14.9328 27.0302 14.9328Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="h-line" />
                            </>
                          )}
                      </>
                    );
                  })
                )
              ) : null}

              {!loading3 &&
                privilage.indexOf(access.serviceManegment.addStep) > -1 && (
                  <svg
                    onClick={() => {
                      setNewService({
                        ...newService,
                        steps: [
                          ...newService.steps,
                          {
                            priority: newService.steps.length,
                            isEdit: true,
                          },
                        ],
                      });
                    }}
                    style={{ marginBottom: "30px" }}
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="32" height="32" rx="4" fill="#2E6CDF" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M27.0302 14.9328H17.0671V4.96968C17.0671 4.37996 16.5891 3.90244 15.9999 3.90244C15.4102 3.90244 14.9327 4.37996 14.9327 4.96968V14.9328H4.96958C4.37987 14.9328 3.90234 15.4103 3.90234 16C3.90234 16.5897 4.37987 17.0672 4.96958 17.0672H14.9327V27.0303C14.9327 27.6195 15.4102 28.0976 15.9999 28.0976C16.5891 28.0976 17.0671 27.6195 17.0671 27.0303V17.0672H27.0302C27.6194 17.0672 28.0975 16.5897 28.0975 16C28.0975 15.4103 27.6194 14.9328 27.0302 14.9328Z"
                      fill="white"
                    />
                  </svg>
                )}
            </div>
          )}
        </Form>
        <div
          style={{ justifyContent: "right" }}
          className="d-flex flex-row gap-3 w-100"
        >
          {newService.steps.length > 0 &&
            newService.id &&
            privilage.indexOf(access.serviceManegment.publish) > -1 && (
              <Button
                style={
                  isMobile
                    ? { marginBottom: "20px", width: "33%" }
                    : {
                        marginBottom: "20px",
                        width: "15%",
                      }
                }
                loading={isLoading}
                onClick={async () => {
                  await publishService(newService.id);
                }}
                type="primary"
              >
                Publish
              </Button>
            )}
          {newService.id && (
            <Button
              style={
                isMobile
                  ? { marginBottom: "20px", width: "33%" }
                  : {
                      marginBottom: "20px",
                      width: "15%",
                    }
              }
              loading={isLoading}
              onClick={() => {
                myForm.resetFields();
                setNewService({
                  steps: [],
                  price: [],
                  isEdit: true,
                });
                fetchServices(1, true);
                setIsOpen(false);
              }}
              type="default"
            >
              Save Draft
            </Button>
          )}
        </div>
      </div>
      <Modal
        open={isCropping}
        onCancel={() => setIsCropping(false)}
        footer={null}
        width={600}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "400px",
            background: "#333",
          }}
        >
          <Cropper
            image={imgSrc}
            crop={crop}
            zoom={zoom}
            aspect={16 / 9}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={(_, croppedAreaPixels) =>
              setCroppedAreaPixels(croppedAreaPixels)
            }
          />
        </div>
        <Slider
          min={1}
          max={3}
          step={0.1}
          value={zoom}
          onChange={(value) => setZoom(value)}
          tooltip={{ formatter: null }}
        />
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button type="primary" onClick={handleCropComplete}>
            Crop
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AddService;
