import { React, useEffect, useState } from "react";
import "./styles.scss";
import { Spin, Tabs, Form, Input, Button, Modal, Dropdown } from "antd";
import { AppstoreFilled } from "@ant-design/icons";
import { useAssets, useMobile } from "../../hooks/index";
import { fetchData, putData } from "../../endpoints";
import Admins from "./Admin";
import Roles from "./Roles";
import Privilege from "./Privilege";

export default function AdminManagement() {
  const isMobile = useMobile();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const { getFile } = useAssets();
  const [navMobileItem, setNavMobileItem] = useState(0);
  const items = [
    {
      key: "1",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 0 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => setNavMobileItem(0)}
        >
          Admins
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 1 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => setNavMobileItem(1)}
        >
          Roles
        </button>
      ),
    },
    {
      key: "3",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 2 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => setNavMobileItem(2)}
        >
          Privilege
        </button>
      ),
    },
  ];
  const getUser = async () => {
    setLoading(true);
    var res = await fetchData(`user`);
    if (res.status === 200) {
      setUser(res.data.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <div
      style={
        !isMobile
          ? { backgroundColor: "white", minHeight: "100vh" }
          : {
              backgroundColor: "white",
              minHeight: "100vh",
              paddingRight: "5%",
              paddingLeft: "5%",
              // marginTop: "70px",
            }
      }
      className="d-flex flex-column w-100 py-3"
    >
      <div
        style={
          isMobile
            ? { paddingLeft: "0px", alignItems: "center" }
            : { paddingLeft: "55px", alignItems: "center" }
        }
        className={`d-flex flex-row ${isMobile ? "gap-3" : " gap-4"}`}
      >
        <img
          src={getFile("Profile")}
          width={isMobile ? "70px" : "100px"}
          height={isMobile ? "70px" : "100px"}
          style={{ borderRadius: "45px" }}
        />
        <div
          className={`d-flex flex-column ${isMobile && "col-6"}`}
          style={{ justifyContent: "center" }}
        >
          {loading ? (
            <Spin />
          ) : (
            <span
              style={
                isMobile
                  ? { fontWeight: "700", fontSize: "16px" }
                  : { fontWeight: "700", fontSize: "20px" }
              }
            >
              {user.firstName + " " + user.lastName}
            </span>
          )}
          <span
            style={
              isMobile
                ? {
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "rgba(161, 129, 58, 1)",
                    marginTop: "0.2rem",
                  }
                : {
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgba(161, 129, 58, 1)",
                  }
            }
          >
            Admin
          </span>
        </div>
        {isMobile && (
          <Dropdown menu={{ items }} placement="bottomLeft">
            <button
              className="menu-btn-mobile"
              style={{ marginBottom: "40px" }}
            >
              <AppstoreFilled style={{ fontSize: "20px" }} />
            </button>
          </Dropdown>
        )}
      </div>{" "}
      {isMobile && <hr style={{ margin: "5% 5%" }}></hr>}
      <div
        style={
          isMobile
            ? { width: "100%", marginTop: "10px" }
            : { width: "93%", marginTop: "-20px" }
        }
        className="top-tabs d-flex flex-row mx-4 "
        dir="rtl"
      >
        {!isMobile && (
          <Tabs
            size={isMobile ? "small" : "larg"}
            tabBarGutter={18}
            style={{ color: "black", fontWeight: "500", width: "97%" }}
            defaultActiveKey={2}
            tabPosition="top"
            direction="rtl"
            type="card"
            items={[
              {
                label: `Privilege`,
                key: 0,
                children: <Privilege />,
              },
              {
                label: `Roles`,
                key: 1,
                children: <Roles />,
              },

              {
                label: `Admins`,
                key: 2,
                children: <Admins user={user} />,
              },
            ]}
          />
        )}
      </div>
      {isMobile && (
        <div>
          {navMobileItem === 0 && <Admins user={user} />}
          {navMobileItem === 1 && <Roles />}
          {navMobileItem === 2 && <Privilege />}
        </div>
      )}
    </div>
  );
}
