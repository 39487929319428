import { React, useState, useRef, useEffect } from "react";
import "./styles.scss";
import {
  SearchOutlined,
  ExclamationCircleFilled,
  DownOutlined,
  UpOutlined,
  FilterOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { CustomInput } from "../../components/CustomInput";
import { CustomButton } from "../../components/CustomButton";
import { Button, Input, Space, Table, Tooltip, Radio } from "antd";
import Highlighter from "react-highlight-words";
import access from "../../assets/access";
import { deleteData, fetchData, putData, postData } from "../../endpoints";
import Swal from "sweetalert2";
import UserPage from "./userPage";
import { Modal } from "antd";
import { useConfig } from "../../hooks/useConfig";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import {
  useParams,
  useLocation,
  useNavigate,
  redirect,
} from "react-router-dom";
import Cookies from "js-cookie";
import { useMobile } from "../../hooks";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const UserManagement = (
  {
    // users,
    // getUsers,
    // isLoading,
    // setIsLoading,
    // setUsers,
  }
) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [user, setUser] = useState("");
  const [isOpenUser, setIsOpenUser] = useState(false);
  const [isOpenPatient, setIsOpenPatient] = useState(false);
  const searchInput = useRef(null);
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [option, setOption] = useState({});
  const [showRemove, setShowRemove] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [serviceTypesarray, setServiceTypes] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [isopenadduser, setisopenadduser] = useState(false);
  const [newUserData, setNewUserData] = useState({});
  const { userId } = useParams();
  const [typeFilter, setTypeFilter] = useState("");
  const path = useLocation().pathname;
  const isMobile = useMobile();
  const navigator = useNavigate();
  const [users, setUsers] = useState([]);
  const { getUserConfig } = useConfig();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    const savedState = sessionStorage.getItem("modalState");
    if (savedState) {
      setModalData(JSON.parse(savedState));
      setIsModalVisible(JSON.parse(savedState).showModal);
      sessionStorage.removeItem("modalState");
    }
  }, []);

  const getRemovedUsers = async (page = 1, reload = false) => {
    var temp = removed;
    if (reload) {
      temp = [];
    }
    setLoading(true);

    if (removed.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        "admin/user/?deletedAt=false&disabledAt=true&perPage=15&page=" + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setRemoved(temp.concat([...res.data.data]));
        } else {
          setRemoved(temp.concat([...res.data.data, {}]));
        }
      } else {
        Swal.fire({
          title: "",
          text: res,
          icon: "error",
          confirmButtonText: "ok",
        });
      }
    }
    setLoading(false);
  };

  const getPatient = async (user, userId = false, page = 1) => {
    setLoading(true);
    let id = userId ? userId : user.id;
    var res = await fetchData(
      `admin/patient/?userId=${id}&perPage=100&page=${page}`
    );
    if (res.status === 200) {
      setPatients(
        res.data.data.filter((u) => {
          return !u.deletedAt;
        })
      );
    }

    getUserConfig();
    var res2 = JSON.parse(localStorage.getItem("config"));

    var canf = res2.data.data.profile;
    setOption({
      ...option,
      Gender: Object.values(canf.gender),
      Province: Object.keys(Object.values(canf.cities)[0]),
      CareerPosition: Object.values(canf.careers),
      Country: Object.keys(canf.cities),
      City: canf.cities,
      Sex: Object.values(canf.sex),
      Pronouns: Object.values(canf.pronoun),
      Prefix: Object.values(canf.prefix),
    });

    setLoading(false);
  };
  const getServiceTypes = async () => {
    var res = await fetchData("admin/service-type");
    if (res.status == 200) {
      var temp = [];
      res.data.data.map((s) => {
        temp = [...temp, s];
        if (s.children) {
          temp = temp.concat(s.children);
        }
      });
      setServiceTypes(temp);
    }
  };
  const getOneUser = async (id) => {
    setLoading(true);
    var res = await fetchData(`admin/user/${id}`);
    if (res.status == 200) {
      setUser({
        ...res.data.data,
        name: res.data.data.firstName + " " + res.data.data.lastName,
      });
      setIsOpenUser(true);
    } else {
      sessionStorage.setItem(
        "modalState",
        JSON.stringify({
          showModal: true,
          message: "The user was not found!",
        })
      );
      navigator(-1);
    }
    setLoading(false);
  };

  const fetchUsers = async (page = 1, reload = false) => {
    setLoading(true);
    var temp = users;
    if (reload) {
      temp = [];
    }
    if (users.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        "admin/user/?deletedAt=false&disabledAt=false&perPage=15&page=" + page
      );

      if (res.status === 200) {
        var temp2 = res.data.data.map((u, i) => {
          return {
            ...u,
            key: i,
            name:
              u.firstName != null
                ? u.firstName + " " + u.lastName
                : "Name not provided",
            createdAt: new Date(u.createdAt).toDateString(),
          };
        });
        if (res.data.data.length < 15) {
          setUsers(
            temp
              .filter((a, i) => {
                return a.email;
              })
              .concat([...temp2])
          );
        } else {
          setUsers(
            temp
              .filter((a, i) => {
                return a.email;
              })
              .concat([...temp2, {}])
          );
        }
      } else {
        Swal.fire({
          title: "",
          text: res,
          icon: "error",
          confirmButtonText: "ok",
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (privilage.indexOf(access.userManegment.indexUser) == -1) {
      navigator("/setting");
      return;
    }
    if (
      privilage.indexOf(access.userManegment.showUser) > -1 &&
      user == "" &&
      userId
    ) {
      getOneUser(userId);
      return;
    }
    getRemovedUsers();
    if (privilage.indexOf(access.userManegment.showPatient) > -1) {
      getPatient(user);
      getServiceTypes();
    }
  }, [user]);
  useEffect(() => {
    fetchUsers(1);
  }, []);

  const removeData = removed
    .filter((u) => {
      return "deletedAt" in u;
    })
    .map((u, i) => {
      return {
        ...u,
        key: i,
        name:
          u.firstName != null
            ? u.firstName + " " + u.lastName
            : "Name not provided",
        deletedAt: new Date(u.deletedAt).toDateString(),
      };
    });
  const { confirm } = Modal;

  const showDeleteConfirm = async (arr) => {
    confirm({
      title: "Are you sure you want to disable this user?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeGrupe(arr);
      },
      onCancel() {},
    });
  };
  const removeUser = async (id) => {
    var res = await putData(`admin/user/${id}/disable`);
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  const enableUser = async (id) => {
    setLoading(true);
    var res = await putData(`admin/user/${id}/enable`);
    if (res.status == 200) {
      await getRemovedUsers(1, true);
      await fetchUsers(1, true);
      Modal.success({ title: "Users have been successfully enabled." });
    } else {
      Modal.error({ title: res });
    }
    setLoading(false);
  };
  const removeGrupe = async (listOfUsers) => {
    let isOk = true;

    for (var item in listOfUsers) {
      var e = listOfUsers[item];
      var res = await removeUser(e.id);
      if (!res) {
        isOk = false;
      }
    }
    if (isOk) {
      await fetchUsers(1, true);
      await getRemovedUsers(1, true);
      setSelectedRowKeys([]);
      Modal.success({ title: "Users have been successfully disabled." });
    } else {
      Modal.error({ title: "There was a problem, please try again." });
    }
  };

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    setLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      await fetchUsers();
    } else {
      let search = "";
      if (dataIndex == "name") search = "fullName";
      else if (dataIndex == "phone") search = "phoneNumber";
      else search = "email";
      var res = await fetchData(`admin/user/?${search}=` + selectedKeys[0]);

      if (res.status == 200) {
        var temp2 = res.data.data.map((u, i) => {
          return {
            ...u,
            key: i,
            name:
              u.firstName != null
                ? u.firstName + " " + u.lastName
                : "Name not provided",
            createdAt: new Date(u.createdAt).toDateString(),
          };
        });
        setUsers(temp2);
      }
    }
    setLoading(false);
  };
  const handleSearchremove = async (selectedKeys, confirm, dataIndex) => {
    setLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      await getRemovedUsers();
    } else {
      let search = "";
      if (dataIndex == "name") search = "fullName";
      else if (dataIndex == "phone") search = "phoneNumber";
      else search = "email";
      var res = await fetchData(
        `admin/user/?deletedAt=false&disabledAt=true&${search}=` +
          selectedKeys[0]
      );

      if (res.status == 200) {
        var temp2 = res.data.data.map((u, i) => {
          return {
            ...u,
            key: i,
            name:
              u.firstName != null
                ? u.firstName + " " + u.lastName
                : "Name not provided",
            createdAt: new Date(u.createdAt).toDateString(),
          };
        });
        setRemoved(temp2);
      }
    }
    setLoading(false);
  };

  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    await fetchUsers(1, true);
  };
  const handleResetremove = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    await getRemovedUsers(1, true);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const getColumnSearchPropsremove = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchremove(selectedKeys, confirm, dataIndex)
          }
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchremove(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetremove(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      width: "20%",

      ...getColumnSearchProps("name"),
      render: (record) => (
        <Tooltip title={record.phoneNumber}>
          {privilage.indexOf(access.userManegment.showUser) > -1 ? (
            <a
              href={path + "/" + record.id}
              style={
                isMobile
                  ? { color: "black", fontSize: "10px" }
                  : { color: "black", fontSize: "13px" }
              }
              className="name-table"
            >
              {record.name}
            </a>
          ) : (
            <p
              style={
                isMobile
                  ? { color: "black", fontSize: "10px" }
                  : { color: "black", fontSize: "13px" }
              }
            >
              {record.name}
            </p>
          )}
        </Tooltip>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "25%",
      ...getColumnSearchProps("email"),
      render: (record) => (
        <p
          style={
            isMobile
              ? { color: "black", fontSize: "10px" }
              : { color: "black", fontSize: "13px" }
          }
        >
          {record}
        </p>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "20%",
      responsive: ["md"],
      ...getColumnSearchProps("phone"),
      render: (record) => (
        <p
          style={
            isMobile
              ? { color: "black", fontSize: "10px" }
              : { color: "black", fontSize: "13px" }
          }
        >
          {record}
        </p>
      ),
    },
    {
      title: "Type",

      dataIndex: "type",
      key: "type",
      width: "15%",
      responsive: ["md"],
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <Radio.Group
            value={typeFilter}
            onChange={async (e) => {
              setLoading(true);

              // setSelectedFilter(e.target.value);
              setTypeFilter(e.target.value);
              var res = await fetchData(`admin/user?type=${e.target.value}`);
              if (res.status == 200) {
                var temp = res.data.data.map((u, i) => {
                  return {
                    ...u,
                    key: i,
                    name:
                      u.firstName != null
                        ? u.firstName + " " + u.lastName
                        : "Name not provided",
                    createdAt: new Date(u.createdAt).toDateString(),
                  };
                });
                setUsers(temp);
                // data=[]
              }
              setLoading(false);
            }}
          >
            <Radio value="super_user">Super User</Radio>
            <Radio value="client">Client</Radio>
            <Radio value="admin">Admin</Radio>
          </Radio.Group>
          {typeFilter && (
            <Button
              onClick={() => {
                setTypeFilter(null);
                fetchUsers(1, true);
                // fetchData("");
              }}
              style={{ marginTop: 8 }}
            >
              Clear
            </Button>
          )}
        </div>
      ),
      filterIcon: <FilterOutlined />,
      render: (record) => (
        <p
          style={
            isMobile
              ? { color: "black", fontSize: "10px" }
              : { color: "black", fontSize: "12px" }
          }
        >
          {record}
        </p>
      ),
    },
    {
      title: <div>Registration Date</div>,
      dataIndex: "createdAt",
      key: "createdAt",
      responsive: ["md"],
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <p
          style={
            isMobile
              ? { color: "black", fontSize: "10px" }
              : { color: "black", fontSize: "12px" }
          }
        >
          {record}
        </p>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) =>
        privilage.indexOf(access.userManegment.disableUser) > -1 && (
          <a
            onClick={async (e) => {
              e.stopPropagation();
              await showDeleteConfirm([u]);
            }}
            style={
              isMobile
                ? { color: "#BC2323", fontSize: "10px" }
                : { color: "#BC2323", fontSize: "13px" }
            }
          >
            Disable
          </a>
        ),
    },
  ];
  const columns2 = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      width: "20%",
      onCell: (record) => {
        return {
          onClick: () => {
            setUser(record);
            setIsOpenUser(true);
          },
        };
      },
      ...getColumnSearchPropsremove("name"),
      render: (record) =>
        privilage.indexOf(access.userManegment.showUser) > -1 ? (
          <a href={path + "/" + record.id} style={{ color: "black" }}>
            {record.name}
          </a>
        ) : (
          <p>{record.name}</p>
        ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "30%",
      ...getColumnSearchPropsremove("email"),
    },
    {
      title: "Date Disabled",
      dataIndex: "disabledAt",
      key: "disabledAt",
      responsive: ["md"],
      sorter: (a, b) => new Date(a.disabledAt) - new Date(b.disabledAt),
      sortDirections: ["descend", "ascend"],
      render: (u) => <div>{u ? new Date(u).toLocaleDateString() : ""}</div>,
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (r) =>
        privilage.indexOf(access.userManegment.enableUser) > -1 && (
          <h2
            style={{ cursor: "pointer" }}
            onClick={() => {
              enableUser(r.id);
            }}
          >
            Enable
          </h2>
        ),
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  function EmailValidate(email) {
    return email
      .toLowerCase()
      .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  }

  if (isOpenUser) {
    return (
      <>
        <UserPage
          user={user}
          setUser={setUser}
          setIsOpen={setIsOpenUser}
          isOpenPatient={isOpenPatient}
          setIsOpenPatient={setIsOpenPatient}
          patients={patients}
          getPatient={getPatient}
          patient={patient}
          setPatient={setPatient}
          loading={loading}
          setLoading={setLoading}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          searchText={searchText}
          setSearchText={setSearchText}
          searchedColumn={searchedColumn}
          setSearchedColumn={setSearchedColumn}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          searchInput={searchInput}
          option={option}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          serviceTypesarray={serviceTypesarray}
          isMobile={isMobile}
        />
        <Modal
          visible={isModalVisible}
          onOk={() => {
            setIsModalVisible(false);
          }}
          cancelButtonProps={{ style: { display: "none" } }}
          closeIcon={null}
        >
          <p style={{ fontWeight: "700" }}>
            {modalData?.message || "Something went wrong!"}
          </p>
        </Modal>
      </>
    );
  } else {
    return (
      <div
        style={{ backgroundColor: "white", minHeight: "100vh" }}
        className="d-flex flex-row w-100"
      >
        <div
          className={
            isMobile
              ? "col-11 d-flex flex-column mx-3"
              : "col-11 d-flex flex-column mx-3 my-5 "
          }
        >
          <div className="table-box py-4 align-items-center ">
            <div className="d-flex flex-row align-items-center px-4 py-4 w-100">
              <span
                style={
                  isMobile
                    ? {
                        fontSize: "18px",
                        fontWeight: "700",
                        width: "15%",
                        display: "flex",
                        justifyContent: "center",
                      }
                    : { fontSize: "24px", fontWeight: "700", width: "15%" }
                }
              >
                Users list
              </span>
              <div
                className="d-flex flex-row gap-2"
                style={{
                  justifyContent: "flex-end",
                  width: "85%",
                  fontWeight: "bold",
                }}
              >
                {selectedRowKeys.length > 0 && (
                  <>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedRowKeys([]);
                      }}
                    >
                      Cancel
                    </span>
                    <span
                      onClick={async () => {
                        showDeleteConfirm(
                          users.filter((u) => {
                            let is;
                            for (let i = 0; i < selectedRowKeys.length; i++) {
                              if (u.key === selectedRowKeys[i]) {
                                is = true;
                                break;
                              } else {
                                is = false;
                              }
                            }
                            return is;
                          })
                        );
                      }}
                      style={{ color: "#BC2323", cursor: "pointer" }}
                    >
                      Disable
                    </span>
                  </>
                )}
              </div>
            </div>
            <Table
              scroll={{ x: "auto" }}
              loading={loading}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              footer={() => {
                return (
                  <div
                    onClick={() => {
                      setisopenadduser(true);
                    }}
                    style={{ cursor: "pointer" }}
                    className="d-flex fle-row gap-2 px-4 align-items-center"
                  >
                    <PlusOutlined
                      style={{
                        color: "rgba(46, 108, 223, 1)",
                      }}
                    />
                    <h6
                      style={{
                        color: "rgba(46, 108, 223, 1)",
                        marginBottom: "0",
                      }}
                    >
                      Add User
                    </h6>
                  </div>
                );
              }}
              columns={columns}
              size="small"
              dataSource={users}
              style={{ width: "100%" }}
              pagination={{
                onChange: async (page) => {
                  await fetchUsers(page);
                },
                pageSize: 15,
              }}
            />
          </div>
          <div className="table-box py-4 mx-3 align-items-center ">
            <div
              style={
                isMobile
                  ? {
                      width: "100%",
                      textAlign: "left",
                      fontSize: "18px",
                      fontWeight: "700",
                      marginBottom: "1.5rem",
                    }
                  : {
                      width: "100%",
                      textAlign: "left",
                      fontSize: "24px",
                      fontWeight: "700",
                      marginBottom: "1.5rem",
                    }
              }
            >
              <span style={{ marginRight: "15px" }}>Disabled Users</span>
              {!showRemove ? (
                <DownOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setShowRemove(true);
                  }}
                />
              ) : (
                <UpOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setShowRemove(false);
                  }}
                />
              )}
            </div>
            {showRemove && (
              <Table
                scroll={{ x: "auto" }}
                loading={loading}
                pagination={{
                  onChange: async (page) => {
                    await getRemovedUsers(page);
                  },
                  pageSize: 15,
                }}
                columns={columns2}
                dataSource={removeData}
                style={{ width: "100%" }}
              />
            )}
          </div>
        </div>
        <Modal
          open={isopenadduser}
          footer={(null, null)}
          width={isMobile ? "90vw" : "50vw"}
          style={isMobile ? {} : { top: "5vh" }}
          onCancel={() => {
            setisopenadduser(false);
          }}
          destroyOnClose
          closeIcon={null}
        >
          <div style={{ textAlign: "end" }}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                setisopenadduser(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <g clip-path="url(#clip0_6456_137213)">
                <path
                  d="M9.71012 8.00014L15.6459 2.06441C16.118 1.59228 16.118 0.826413 15.6459 0.354269C15.1738 -0.11809 14.4079 -0.11809 13.9358 0.354269L7.99997 6.29025L2.06442 0.354507C1.59229 -0.117852 0.826181 -0.117852 0.354272 0.354507C-0.118091 0.826633 -0.118091 1.5925 0.354272 2.06465L6.28982 8.00014L0.354272 13.9359C-0.118091 14.408 -0.118091 15.1739 0.354272 15.646C0.590333 15.8821 0.899664 16 1.2092 16C1.51852 16 1.82811 15.8821 2.06413 15.646L7.99991 9.71052L13.9357 15.646C14.1718 15.8821 14.4815 16 14.7906 16C15.0997 16 15.4095 15.8821 15.6455 15.646C16.1177 15.1739 16.1177 14.408 15.6455 13.9359L9.71012 8.00014Z"
                  fill="#4C4C4C"
                />
              </g>
              <defs>
                <clipPath id="clip0_6456_137213">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            className={
              isMobile
                ? " info d-flex flex-column justify-content-center align-items-center gap-4 py-2"
                : " info d-flex flex-column justify-content-center align-items-center gap-4 py-2 px-5"
            }
          >
            <h2 style={isMobile ? { fontSize: "18px" } : {}}>Add a new user</h2>
            <CustomInput
              label={"First Name"}
              required
              fullWidth
              isRow={!isMobile}
              value={patient.firstName}
              onChange={(e) => {
                setNewUserData({ ...newUserData, firstName: e });
              }}
            />
            <CustomInput
              label={"Last Name"}
              required
              isRow={!isMobile}
              fullWidth
              value={patient.lastName}
              onChange={(e) => {
                setNewUserData({ ...newUserData, lastName: e });
              }}
            />
            <CustomInput
              label={"Email"}
              // required
              fullWidth
              isRow={!isMobile}
              value={patient.firstName}
              onChange={(e) => {
                setNewUserData({ ...newUserData, email: e });
              }}
            />
            <div
              style={
                !isMobile
                  ? {
                      alignItems: "center",
                      marginLeft: "20px",
                      // marginRight: "15px",
                      // justifyContent:"space-between",
                      // paddingLeft: "10px",
                    }
                  : { marginLeft: "20px", marginRight: "15px" }
              }
              className={
                !isMobile
                  ? "d-flex flex-row w-100 gap-2"
                  : "d-flex flex-column w-100 gap-1"
              }
            >
              <span
                style={{ fontSize: "12px", fontWeight: "600" }}
                className="col-3"
              >
                Phone Number
              </span>
              <PhoneInput
                className="col-11 col-lg-8 my-1 phon-input"
                placeholder="Enter phone number"
                value={newUserData.phone}
                defaultCountry="CA"
                error={
                  newUserData.phone
                    ? isValidPhoneNumber(newUserData.phone)
                      ? undefined
                      : "Invalid phone number"
                    : "Phone number required"
                }
                onChange={(v) => {
                  setNewUserData({
                    ...newUserData,
                    phone: v,
                  });
                }}
              />
            </div>

            <Button
              loading={loading}
              type="primary"
              className="col-3"
              onClick={async () => {
                if (!newUserData.firstName) {
                  Modal.error({
                    title: "First Name cannot be empty.",
                  });
                  return;
                }
                if (!newUserData.lastName) {
                  Modal.error({
                    title: "Last Name cannot be empty.",
                  });
                  return;
                }
                if (newUserData.email && !EmailValidate(newUserData.email)) {
                  Modal.error({
                    title: "Email address isn't valid",
                  });
                  return;
                }

                if (
                  newUserData.phone &&
                  !isValidPhoneNumber(newUserData.phone)
                ) {
                  Modal.error({
                    title: "Phone number isn't valid",
                  });
                  return;
                }

                setLoading(true);
                var data = newUserData;
                if (data.email == "") {
                  delete data.email;
                }
                if (data.phoneNumber == "") {
                  delete data.phoneNumber;
                }

                var res = await postData("admin/user", {
                  ...data,
                  type: "client",
                });

                if (res.status == 201) {
                  setisopenadduser(false);
                  setNewUserData({});
                  fetchUsers(1, true);
                } else {
                  Modal.error({
                    title: res,
                  });
                }
                setLoading(false);
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
        <Modal
          visible={isModalVisible}
          onOk={() => {
            setIsModalVisible(false);
          }}
          cancelButtonProps={{ style: { display: "none" } }}
          closeIcon={null}
        >
          <p style={{ fontWeight: "700" }}>
            {modalData?.message || "Something went wrong!"}
          </p>
        </Modal>
      </div>
    );
  }
};
export default UserManagement;
