import React, { useEffect } from "react";
import "./styles.scss";
import { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import { fetchData } from "../../../endpoints";
function Payment({ purchaseId, payedPrice, totalPrice }) {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    const savedState = sessionStorage.getItem("modalState");
    if (savedState) {
      setModalData(JSON.parse(savedState));
      setIsModalVisible(JSON.parse(savedState).showModal);
      sessionStorage.removeItem("modalState");
    }
  }, []);
  const getPayments = async () => {
    setLoading(true);
    var res = await fetchData(`admin/service-purchase/${purchaseId}/payment`);
    if (res.status == 200) {
      setPayments(res.data.data);
    } else {
      Modal.error({ title: res.data.message });
    }
    setLoading(false);
  };
  useEffect(() => {
    getPayments();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (u) => <div>{u / 100}</div>,
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
    },
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "x",
    //   render: (record) => {
    //     if (record.status != "success")
    //       return (
    //         <div className="d-flex flex-row gap-2">
    //           {" "}
    //           <Button
    //             style={{ fontWeight: "500", color: "rgba(20, 166, 80, 1)" }}
    //             type="default"
    //           >
    //             Approve
    //           </Button>
    //           <Button
    //             style={{ fontWeight: "500", color: "rgba(182, 32, 37, 1)" }}
    //             type="default"
    //           >
    //             Decline
    //           </Button>
    //         </div>
    //       );
    //   },
    // },
  ];
  const columns2 = [
    {
      title: "Step ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "serviceStep",
      key: "serviceStep",
      render: (record) => {
        return record.title;
      },
    },
    {
      title: "Amount",
      dataIndex: "serviceStep",
      key: "serviceStep",
      render: (record) => {
        return record.price;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Approved At",
      dataIndex: "approvedAt",
      key: "approvedAt",
      render: (u) => {
        if (u) return new Date(u).toLocaleDateString();
      },
    },
    {
      title: "Approved By Id",
      dataIndex: "approvedById",
      key: "approvedById",
      render: (u) => {
        return <a href={"/users/" + u}>{u}</a>;
      },
    },
  ];
  return (
    <div className="d-flex flex-column gap-3 w-100 px-3 ">
      <h2
        style={{
          fontSize: "20px",
          fontWeight: "600",
          marginTop: "-20px",
          marginLeft: "40px",
        }}
      >
        Payments Info
      </h2>
      <div
        style={{
          marginLeft: "20px",
          color: "rgba(24, 50, 78, 1)",
          display: "flex",
          flex: "row",
          gap: "15px",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        <div>Paid: ${payedPrice}</div>
        <div>Debt: ${totalPrice - payedPrice}</div>
        <div>Total: $ {totalPrice}</div>
      </div>
      <Table
        loading={loading}
        expandable={{
          expandedRowRender: (record) => (
            <Table columns={columns2} dataSource={record.steps} />
          ),
          rowExpandable: (record) => true,
        }}
        dataSource={payments}
        columns={columns}
      />
      <Modal
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={null}
      >
        <p style={{ fontWeight: "700" }}>
          {modalData?.message || "Something went wrong!"}
        </p>
      </Modal>
    </div>
  );
}
export default Payment;
