import { useEffect, useState } from "react";
import "./styles.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ServiceCard from "./ServiceCard";
import ServicePage from "./ServicePage";
import { fetchData } from "../../../endpoints";
import { Spin, Empty, Modal } from "antd";
import { useMobile } from "../../../hooks";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import access from "../../../assets/access";
import Cookies from "js-cookie";
import full from "../../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;

const Services = ({ patientId, name, mrn, patient }) => {
  const path = useLocation().pathname;
  const { serviceId, tempStep } = useParams();
  const [active, setActive] = useState(path.includes("in-progress") ? 1 : 0);
  const [services, setServices] = useState([]);
  const [userServices, setUserServices] = useState([]);
  const [userComplateServices, setComplateUserServices] = useState([]);
  const [current, setCurrent] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPurchase, setIsPurchase] = useState(false);
  const [coverUrl, setCoverUrl] = useState("");
  const isMobile = useMobile();
  const navigator = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  const fetchServices = async () => {
    setLoading(true);
    var res = await fetchData(
      `admin/service/?deactivatedAt=false&deletedAt=false&publishedAt=true&perPage=100&page=1`
    );
    if (res.status == 200) {
      setServices(res.data.data);
    }
    if (privilage.indexOf(access.patientManegment.service.indexpurchase) > -1) {
      var res2 = await fetchData(
        `admin/service-purchase/?patientId=` + patientId
      );
      if (res2.status == 200) {
        var temp1 = [];
        var temp2 = [];
        res2.data.data.map((s) => {
          if (s.status == "done_by_clinic") {
            temp1 = [...temp1, s];
          } else {
            if (s.status != "canceled") temp2 = [...temp2, s];
          }
        });
        setComplateUserServices(temp1);
        setUserServices(temp2);
        if (temp2.length < 1 && path.includes("in-progress")) {
          setActive(0);
          navigator(`/patients/${patientId}/services`);
        }
      }
    }
    var res2 = await fetchData("user/config");
    if (res2.status == 200) {
      setCoverUrl(res2.data.data.storage.bucket.url);
    }
    setLoading(false);
  };

  const fetchService = async () => {
    setLoading(true);
    var domain = path.includes("in-progress")
      ? `admin/service-purchase/${serviceId}`
      : `admin/service/${serviceId}`;
    var res = await fetchData(domain);
    if (res.status == 200) {
      if (path.includes("in-progress"))
        setCurrent({
          id: serviceId,
          id: serviceId,
          description: res.data.data.description,
          service: res.data.data.service,
          status: res.data.data.status,
        });
      else {
        setCurrent(res.data.data);
      }
      setIsPurchase(path.includes("in-progress"));
      setIsOpen(true);
    } else {
      Modal.error({ title: res });
      await fetchServices();
    }

    setLoading(false);
  };

  useEffect(() => {
    const savedState = sessionStorage.getItem("modalState");
    if (savedState) {
      setModalData(JSON.parse(savedState));
      setIsModalVisible(JSON.parse(savedState).showModal);
      sessionStorage.removeItem("modalState");
    }
    if (serviceId) {
      fetchService();
    }

    fetchServices();
  }, []);

  return (
    <div dir="ltr" className="d-flex flex-column w-100 gap-4">
      {isOpen ? (
        tempStep ? (
          <ServicePage
            current={current}
            setIsOpen={setIsOpen}
            patientId={patientId}
            isPurchase={isPurchase}
            fetchService={fetchServices}
            name={name}
            mrn={mrn}
            patient={patient}
            setIsPurchase={setIsPurchase}
            tempStep={tempStep}
            url={coverUrl}
          />
        ) : (
          <ServicePage
            current={current}
            setIsOpen={setIsOpen}
            patientId={patientId}
            isPurchase={isPurchase}
            fetchService={fetchServices}
            name={name}
            mrn={mrn}
            patient={patient}
            setIsPurchase={setIsPurchase}
            url={coverUrl}
          />
        )
      ) : (
        <>
          {" "}
          {isMobile && (
            <div
              style={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}
            >
              Services
            </div>
          )}
          <div className="services1 d-flex flex-row w-50 gap-4">
            <button
              className={
                active == 0
                  ? `active ${isMobile && "col-6"}`
                  : `${isMobile && "col-6"}`
              }
              onClick={() => {
                setActive(0);
                navigator(`/patients/${patientId}/services`);
              }}
            >
              All Services
            </button>
            <button
              className={
                active == 1
                  ? `active ${isMobile && "col-6"}`
                  : `${isMobile && "col-6"}`
              }
              onClick={() => {
                setActive(1);
                navigator(`/patients/${patientId}/services/in-progress`);
              }}
            >
              In Progress
            </button>
            <button
              className={
                active == 2
                  ? `active ${isMobile && "col-6"}`
                  : `${isMobile && "col-6"}`
              }
              onClick={() => {
                setActive(2);
              }}
            >
              Completed
            </button>
          </div>
          <div>
            {active == 0 ? (
              <Home
                services={services}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                loading={loading}
                setIsPurchase={setIsPurchase}
                isMobile={isMobile}
                coverUrl={coverUrl}
              />
            ) : active == 1 ? (
              <InProgress
                services={userServices}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                loading={loading}
                setIsPurchase={setIsPurchase}
                isMobile={isMobile}
                allService={services}
                coverUrl={coverUrl}
              />
            ) : (
              <Completed
                services={userComplateServices}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                loading={loading}
                setIsPurchase={setIsPurchase}
                isMobile={isMobile}
                allService={services}
                coverUrl={coverUrl}
              />
            )}
          </div>
        </>
      )}
      <Modal
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={null}
      >
        <p style={{ fontWeight: "700" }}>
          {modalData?.message || "Something went wrong!"}
        </p>
      </Modal>
    </div>
  );
};
const responsive = {
  largdesktop: {
    breakpoint: { max: 3000, min: 1991 },
    items: 5,
    slidesToSlide: 2, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1990, min: 991 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 991, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const Home = ({
  services,
  setCurrent,
  setIsOpen,
  loading,
  setIsPurchase,
  isMobile,
  coverUrl,
}) => {
  return (
    <div>
      <div className="my-carousel d-flex flex-column gap-4 py-4">
        <h2 className="title" style={isMobile ? { fontSize: "16px" } : {}}>
          All Services
        </h2>
        {loading ? (
          <Spin size="large" />
        ) : (
          <Carousel
            swipeable={true}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            // autoPlay={true}
            // autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            dotListClass="custom-dot-list-style"
            itemClass="px-3"
            renderDotsOutside={true}
          >
            {services.map((s) => {
              return (
                <ServiceCard
                  service={s}
                  buttonType={"More"}
                  setCurrent={setCurrent}
                  setIsOpen={setIsOpen}
                  isPurchase={false}
                  setIsPurchase={setIsPurchase}
                  isMobile={isMobile}
                  allService={services}
                  url={coverUrl}
                />
              );
            })}
          </Carousel>
        )}
      </div>
      <div
        // style={{ backgroundColor: "rgba(39, 155, 214, 0.04)" }}
        className="d-flex flex-column gap-4 py-4"
      >
        <h2 className="title" style={isMobile ? { fontSize: "16px" } : {}}>
          Similar Services
        </h2>
        {loading ? (
          <Spin size="large" />
        ) : (
          <Carousel
            swipeable={true}
            rewindWithAnimation={true}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            // autoPlay={true}
            // autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            dotListClass="custom-dot-list-style"
            itemClass="px-3"
            renderDotsOutside={true}
          >
            {services.map((s) => {
              return (
                <ServiceCard
                  service={s}
                  buttonType={"More"}
                  setCurrent={setCurrent}
                  setIsOpen={setIsOpen}
                  isPurchase={false}
                  setIsPurchase={setIsPurchase}
                  isMobile={isMobile}
                  allService={services}
                  url={coverUrl}
                />
              );
            })}
          </Carousel>
        )}
      </div>
    </div>
  );
};
const InProgress = ({
  services,
  setCurrent,
  setIsOpen,
  loading,
  setIsPurchase,
  isMobile,
  allService,
  coverUrl,
}) => {
  return (
    <div className="my-carousel d-flex flex-column gap-4 py-4">
      <h2 className="title" style={isMobile ? { fontSize: "16px" } : {}}>
        In Progress Services
      </h2>
      {loading ? (
        <Spin size="large" />
      ) : services.length == 0 ? (
        <div style={{ margin: "auto" }}>
          <Empty />
        </div>
      ) : (
        <Carousel
          swipeable={true}
          rewindWithAnimation={true}
          renderDotsOutside={true}
          showDots={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          // autoPlay={true}
          // autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          dotListClass="custom-dot-list-style"
          itemClass="px-3"
        >
          {services.map((s) => {
            return (
              <ServiceCard
                service={s}
                buttonType={s.started ? "Continue" : "Start"}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                isPurchase={true}
                setIsPurchase={setIsPurchase}
                isMobile={isMobile}
                allService={allService}
                url={coverUrl}
              />
            );
          })}
        </Carousel>
      )}
    </div>
  );
};
const Completed = ({
  services,
  setCurrent,
  setIsOpen,
  loading,
  setIsPurchase,
  isMobile,
  allService,
  coverUrl,
}) => {
  return (
    <div className="my-carousel d-flex flex-column gap-4 py-4">
      <h2 className="title" style={isMobile ? { fontSize: "16px" } : {}}>
        Completed Services
      </h2>
      {services.length == 0 ? (
        <div style={{ margin: "auto" }}>
          <Empty />
        </div>
      ) : loading ? (
        <Spin size="large" />
      ) : (
        <Carousel
          swipeable={true}
          renderDotsOutside={true}
          rewindWithAnimation={true}
          showDots={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          // autoPlay={true}
          // autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          dotListClass="custom-dot-list-style"
          itemClass="px-3"
        >
          {services.map((s) => {
            return (
              <ServiceCard
                service={s}
                buttonType={"Readd"}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                isPurchase={true}
                setIsPurchase={setIsPurchase}
                isMobile={isMobile}
                allService={allService}
                url={coverUrl}
              />
            );
          })}
        </Carousel>
      )}
    </div>
  );
};
export default Services;
