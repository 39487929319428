import "./styles.scss";
import {
  Table,
  Modal,
  Input,
  Space,
  Button,
  Radio,
  Form,
  Select,
  Checkbox,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  DeleteTwoTone,
  PlusOutlined,
  SearchOutlined,
  ExclamationCircleFilled,
  EditOutlined,
} from "@ant-design/icons";
import { useMobile } from "../../hooks";
import { useState, useRef, useEffect } from "react";
import NewQuestionnaire from "./newQuestionnaire";
import ConfirmReport from "./ConfirmReport";
import {
  deleteData,
  fetchData,
  postData,
  putData,
  putFormData,
} from "../../endpoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
const ReportManagement = ({ patientId, patient }) => {
  const path = useLocation().pathname;
  const { reportId } = useParams();
  const isMobile = useMobile();
  const navigator = useNavigate();
  const searchInput = useRef(null);
  const { confirm } = Modal;
  const [reports, setReports] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selected, setSelected] = useState(-1);
  const [selectedReport, setSelectedReport] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const showDeleteConfirm = async (report) => {
    confirm({
      title: "Are you sure remove this report?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeReport(report.id);
        fetchReports();
      },
      onCancel() {},
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "30%",
      ...getColumnSearchProps("title"),
    },
    // {
    //   title: "Template Id",
    //   dataIndex: "templateId",
    //   key: "templateId",
    //   width: "20%",
    //   ...getColumnSearchProps("templateId"),
    // },
    {
      title: "Purchase Id",
      dataIndex: "purchaseId",
      key: "purchaseId",
      width: "20%",
      render: (u) => {
        return (
          <a
            href={path.replace("/reports", `/services/in-progress/${u}`)}
            style={{ color: "black" }}
          >
            {u}
          </a>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "25%",
      render: (i) => {
        return new Date(i).toLocaleString();
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <div
          style={{ marginRight: "15px" }}
          dir="rtl"
          className="d-flex flex-row gap-4"
        >
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={async () => {
              setIsLoading(true);
              var res = await fetchData("admin/report/" + u.id);
              if (res.status == 200) {
                setSelectedReport(res.data.data);
                setIsLoading(false);
                setIsEdit(true);
                navigator(path + "/" + u.id);
                setAddNew(true);
              } else {
                Modal.error({ title: res });
                setIsLoading(false);
              }
            }}
          >
            Edit
          </span>
          <span
            style={{
              color: "#BC2323",

              cursor: "pointer",
            }}
            onClick={async () => {
              await showDeleteConfirm(u);
            }}
          >
            Remove
          </span>
        </div>
      ),
    },
  ];
  const fetchReports = async () => {
    setIsLoading(true);
    var res = await fetchData("admin/report/?patientId=" + patientId);
    var res2 = await fetchData("admin/report/template/index");
    if (res.status == 200) {
      setReports(
        res.data.data.map((r, i) => {
          return { ...r, key: i };
        })
      );
    } else {
      Modal.error({ title: res });
    }
    if (res2.status == 200) {
      setTemplates(
        res2.data.data.filter((r) => {
          return !r.deletedAt;
        })
      );
    }
    setIsLoading(false);
  };
  const removeReport = async (id) => {
    setIsLoading(true);
    var res = await deleteData("admin/report/" + id);
    if (res.status == 200) {
      Modal.success({ title: res.data.message });
    } else {
      Modal.error({ title: res });
    }
    setIsLoading(false);
  };
  const fetchPurchase = async () => {
    setIsLoading(true);
    var res = await fetchData("admin/service-purchase/?patientId=" + patientId);
    if (res.status == 200) {
      setPurchases(
        res.data.data.map((r, i) => {
          return { ...r, key: i };
        })
      );
    } else {
      Modal.error({ title: res });
    }
    setIsLoading(false);
  };
  const fechReport = async (id, confirm = false) => {
    var res = await fetchData("admin/report/" + id);
    if (res.status == 200) {
      if (confirm == false) {
        setSelectedReport(res.data.data);
        setIsEdit(true);
        setIsLoading(false);
        setAddNew(true);
      }
      if (confirm == true) {
        setSelectedReport(res.data.data);
        setShowReport(true);
      }
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (reportId && !addNew && !showReport) {
      fechReport(reportId);
    }
    if (reportId && path.includes("/confirm")) {
      fechReport(reportId, true);
      // setShowReport(true);
    }
    fetchReports();
    fetchPurchase();
  }, [showReport, addNew]);
  return (
    <div
      style={{ backgroundColor: "white", minHeight: "100vh" }}
      className="d-flex flex-column w-100"
      dir="ltr"
    >
      {showReport ? (
        <ConfirmReport
          setSelected={setSelectedReport}
          setIsOpen={setShowReport}
          template={selectedReport}
          setIsEdit={setIsEdit}
          patient={patient}
        />
      ) : addNew ? (
        <NewQuestionnaire
          template={selectedReport}
          setIsOpen={setAddNew}
          setShowReport={setShowReport}
          patientId={patientId}
          setSelectedReport={setSelectedReport}
          isEdit={isEdit}
        />
      ) : (
        <div className={`${isMobile ? "col-12 d-flex flex-column my-2" : ""}`}>
          <div className={"report d-flex flex-column px-3 my-3 gap-4"}>
            <h1 style={{ fontSize: "24px", fontWeight: "700" }}>Reports</h1>
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={reports.filter((f) => {
                return !f.deletedAt;
              })}
              size="middle"
              style={{ width: "90%" }}
              footer={() => {
                return (
                  <div
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    className="d-flex fle-row gap-2 px-4 align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <PlusOutlined
                      style={{
                        color: "rgba(46, 108, 223, 1)",
                      }}
                    />
                    <h6
                      style={{
                        color: "rgba(46, 108, 223, 1)",
                        marginBottom: "0",
                      }}
                    >
                      Add New Report
                    </h6>
                  </div>
                );
              }}
            />
          </div>
        </div>
      )}
      <Modal
        closeIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg>
        }
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
      >
        <div className="d-flex flex-row gap-3 col-12 justify-content-center my-5 ">
          <Button
            onClick={() => {
              setIsEdit(false);
              setSelectedReport(null);
              setIsOpen(false);
              setIsOpen3(true);
            }}
            type="primary"
            size={isMobile ? "small" : "medium"}
          >
            Create new template
          </Button>
          <Button
            onClick={() => {
              setIsEdit(false);
              setIsOpen(false);
              setIsOpen2(true);
            }}
            size={isMobile ? "small" : "medium"}
          >
            Choose from existing templates
          </Button>
        </div>
      </Modal>
      <Modal
        closeIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg>
        }
        open={isOpen2}
        onCancel={() => setIsOpen2(false)}
        onOk={async () => {
          setIsLoading(true);
          var res = await putData("admin/report/" + selected + "/clone");
          // var res = await fetchData("admin/report/" + selected);
          if (res.status == 200) {
            var res2 = await fetchData("admin/report/" + res.data.data.id);
            var temp;
            if (res2.status == 200) temp = res2.data.data;
            // delete temp.id;
            setSelectedReport({ ...temp, patientId: patientId });
            setIsEdit(true);
            setIsOpen2(false);
            setIsOpen3(true);
            setIsLoading(false);
          } else {
            Modal.error({ title: res });
            setIsLoading(false);
          }
        }}
        okText="Done"
        confirmLoading={isLoading}
      >
        <div className="d-flex flex-column gap-3 col-12 mb-3 ">
          <h3 style={{ fontWeight: "600", fontSize: "18px" }}>
            Choose Question Template
          </h3>
          <div
            className={
              isMobile ? "d-flex flex-column mx-1" : "d-flex flex-column mx-3"
            }
            style={{
              border: "1px solid rgba(217, 217, 217, 1)",
              height: "40vh",
              overflowY: "scroll",
              borderRadius: "10px",
              paddingRight: "0",
              overflowX:"hidden"
            }}
          >
            <Radio.Group
              onChange={(e) => {
                setSelected(e.target.value);
              }}
              className="col-12"
              value={selected}
            >
              <Space direction="vertical" className="col-12">
                {templates.map((t) => {
                  return (
                    <>
                      <div className="d-flex flex-row col-12">
                        <Radio
                          style={{ fontWeight: "600" }}
                          className="py-2 px-4 col-10"
                          value={t.id}
                        >
                          {t.title}
                        </Radio>
                        <EditOutlined
                          style={{
                            fontSize: "18px",
                            marginRight: "10px",
                          }}
                          onClick={async () => {
                            setIsLoading(true);

                            var res = await fetchData("admin/report/" + t.id);

                            if (res.status == 200) {
                              // var temp;
                              // temp = res.data.data;
                              setSelectedReport(res.data.data);
                              navigator(path + "/" + res.data.data.id);
                              setIsEdit(true);
                              setIsOpen2(false);
                              setAddNew(true);
                              setIsLoading(false);
                            } else {
                              Modal.error({ title: res });
                              setIsLoading(false);
                            }
                          }}
                        />
                        <DeleteTwoTone
                          onClick={() => {
                            showDeleteConfirm(t);
                          }}
                          style={{
                            fontSize: "18px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: "1.5px",
                          backgroundColor: "rgba(217, 217, 217, 1)",
                        }}
                      />
                    </>
                  );
                })}
              </Space>
            </Radio.Group>
            <div
              style={{
                width: "100%",
                height: "60px",
                textAlign: "center",
                backgroundColor: "rgba(50, 156, 0, 0.25)",
                fontWeight: "600",
                fontSize: "15px",
                paddingTop: "10px",
              }}
              onClick={() => {
                setIsOpen2(false);
                setUploadOpen(true);
              }}
            >
              Add from exel file
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        closeIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg>
        }
        open={isOpen3}
        onOk={() => {
          setIsOpen3(false);
          if (selectedReport) navigator(path + "/" + selectedReport.id);
          setAddNew(true);
        }}
        onCancel={() => setIsOpen3(false)}
        // footer={[null,Ok]}
      >
        <div className="d-flex flex-column gap-3 col-12 justify-content-center align-items-center my-5 ">
          <h4 style={{ fontSize: "16px", fontWeight: "600" }}>
            Do you want to link this report to one of the purchased services?{" "}
          </h4>
          <Form.Item className="col-10" label={"Purchased Services"}>
            <Select
              onChange={(e) => {
                if (selectedReport)
                  setSelectedReport({
                    ...selectedReport,
                    purchaseId: e,
                  });
                else {
                  setSelectedReport({
                    purchaseId: e,
                  });
                }
              }}
              options={purchases.map((p) => {
                return { value: p.id, label: p.service.title };
              })}
            />
          </Form.Item>
          <Checkbox> I dont want</Checkbox>
        </div>
      </Modal>
      <Modal
        confirmLoading={isLoading}
        closeIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
              fill="black"
            />
          </svg>
        }
        open={uploadOpen}
        okText={"Confirm"}
        onOk={async () => {
          setIsLoading(true);
          var res = await postData("admin/report", {
            title: selectedReport.title,
            patientId: patientId,
          });
          if (res.status !== 201) {
            Modal.error({ title: res });
            setUploadOpen(false);
            setIsLoading(false);
            return;
          }
          let data = new FormData();
          data.append("file", selectedReport.file);
          var res2 = await putFormData(
            `admin/report/${res.data.data.id}/import`,
            data
          );
          if (res2.status != 200) {
            Modal.error({ title: res2 });
            setUploadOpen(false);
            setIsLoading(false);
            return;
          }
          var res3 = await fetchData("admin/report/" + res.data.data.id);
          var temp;
          if (res3.status == 200) temp = res3.data.data;
          setSelectedReport({ ...temp, patientId: patientId });
          setIsEdit(true);
          setIsOpen3(true);
          setUploadOpen(false);
        }}
        onCancel={() => setUploadOpen(false)}
      >
        <div className="d-flex flex-column gap-3 justify-content-center align-items-center col-12">
          <h2 style={{ fontSize: "20px", fontWeight: "600" }}>
            Upload template file{" "}
          </h2>
          <div className="d-flex flex-row gap-3 col-10">
            <span className="col-5">
              <span
                style={{ color: "red", fontSize: "22px", marginRight: "5px" }}
              >
                *
              </span>
              Title of template:
            </span>
            <Input
              value={selectedReport ? selectedReport.title : null}
              onChange={(e) => {
                setSelectedReport({ ...selectedReport, title: e.target.value });
              }}
            />
          </div>
          <FileUploader
            className="col-12"
            label=" "
            handleChange={(file) =>
              setSelectedReport({ ...selectedReport, file: file })
            }
            name="file"
            types={["XLSX"]}
            maxSize={2}
          />
        </div>
      </Modal>
    </div>
  );
};
export default ReportManagement;
